import React, { FC } from "react";
import Title from "../atoms/Title";
import { CardContainer } from "../molecules/CardContainer";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { selectCompany } from "../../Company/services/companyReducer";
import { Box } from "@mui/material";
import Links from "../atoms/Links";
import useBreakpoints from "../services/useBreakpoints";
import { selectCustomers } from "../../Customer/services/customersReducer";
import { Profile } from "../../AuthProfile/utils/_interface";
import { Company } from "../../Company/utils/_interface";
import { Race } from "../../Race/utils/_interface";
import { Vehicle } from "../../Vehicle/utils/_interface";
import { selectRaces } from "../../Race/services/racesReducer";
import { selectProfile } from "../../AuthProfile/services/profileReducer";

interface DashboardSectionProps {
  seeMorRoute: string;
  title?: string;
  addCardLabel?: string;
  emptyCardLabel?: string;
  addOnClick?: () => void;
  sectionInfo?: Company | Race[] | Profile[] | Vehicle[];
  cardBuilder?: JSX.Element | JSX.Element[];
}

const DashboardSection: FC<DashboardSectionProps> = ({
  title,
  addCardLabel,
  emptyCardLabel,
  sectionInfo,
  cardBuilder,
  seeMorRoute,
  addOnClick,
}) => {
  const races = useSelector(selectRaces);
  const company = useSelector(selectCompany);
  const profile = useSelector(selectProfile);
  const customers = useSelector(selectCustomers);
  const { downSm, downMd, downLg } = useBreakpoints();

  const content = {
    null: "0",
    seeMore: "Voir plus",
  };

  const handleTitleInfo = () => {
    switch (sectionInfo) {
      case races:
        return `${title} (${races?.length ? races?.length : content.null})`;
      case company.drivers:
        return `${title} (${company.drivers?.length ? company.drivers?.length : content.null})`;
      case company.vehicles:
        return `${title} (${company.vehicles?.length ? company.vehicles?.length : content.null})`;
      default:
        return `${title} (${customers.length ? customers.length : content.null})`;
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" sx={{ marginBottom: ".5rem" }}>
        <Title
          title={handleTitleInfo()}
          component="h1"
          variant={downLg ? "body1" : "h6"}
          fontWeight="bold"
          sxTitle={{ marginRight: "1rem" }}
        />
        <Links title={content.seeMore} to={seeMorRoute} />
      </Box>
      <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent={downSm ? "center" : "start"}>
        {profile.account?.accountTypeId === 1 && (
          <CardContainer
            add
            large={!downMd}
            title={addCardLabel}
            icon={<AddIcon fontSize="large" />}
            onClick={addOnClick}
          />
        )}
        {Array.isArray(sectionInfo) && sectionInfo?.length ? (
          cardBuilder
        ) : (
          <CardContainer empty title={emptyCardLabel} />
        )}
      </Box>
    </Box>
  );
};

export default DashboardSection;
