import React, { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Shared/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { Box, Button as MuiButton, Typography } from "@mui/material";
import { InputField } from "../../Shared/molecules/fields/InputField";
import { Race as RaceInterface } from "../utils/_interface";
import { selectCompany } from "../../Company/services/companyReducer";
import useRaceReq from "../services/useRaceReq";
import { DateField } from "../../Shared/molecules/fields/DateField";
import { SelectField } from "../../Shared/molecules/fields/SelectField";
import { selectCustomers } from "../../Customer/services/customersReducer";
import { AutocompleteField } from "../../Shared/molecules/fields/AutocompleteMapsField";
import { COURSE_TYPE } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { selectRace } from "../services/raceReducer";
import { ErrorProps } from "../../Shared/utils/_interface";
import AddIcon from "@mui/icons-material/Add";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import CustomerForm from "../../Customer/organisms/CustomerForm";
import useBreakpoints from "../../Shared/services/useBreakpoints";

interface RaceFormProps {
  eventDialog?: () => void;
  raceId?: string;
  edit?: boolean;
}

const RaceForm: FC<RaceFormProps> = ({ eventDialog, raceId, edit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useSelector(selectError);
  const company = useSelector(selectCompany);
  const customers = useSelector(selectCustomers);
  const { downMd } = useBreakpoints();
  const race = useSelector(selectRace);
  const { putRace, addRace } = useRaceReq();
  const [open, setOpen] = useState(false);
  const [driverArray, setDriverArray] = useState([]);
  const [customersArray, setCustomersArray] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RaceInterface>({
    // resolver: yupResolver(profileSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    return () => clearTimeout(timer);
  }, [type]);

  useEffect(() => {
    console.log("customersArray --1", customersArray);
    if (Array.isArray(customers) && customers.length) {
      const arrayCache: any = [];
      customers.forEach((value) => {
        arrayCache.push({ key: `${value.id}`, label: `${value.firstName} ${value.lastName}` });
      });
      setCustomersArray(arrayCache);
      console.log("customersArray --2", customersArray);
    }
  }, [open]);

  useEffect(() => {
    if (Array.isArray(company.drivers) && company.drivers.length) {
      const arrayCache: any = [];
      company.drivers.forEach((value) => {
        arrayCache.push({ key: `${value.id}`, label: `${value.firstName} ${value.lastName}` });
      });
      setDriverArray(arrayCache);
    }
  }, []);

  const handleRace = (data) => {
    const body = { ...data };
    if (edit) {
      if (raceId != null) {
        if (company.id != null) {
          putRace(company.id, raceId, body);
          navigate(-1);
        }
      }
    } else {
      if (company.id != null) {
        addRace(company.id, body);
        navigate(-1);
      }
    }

    reset({
      dateTimeRace: new Date(),
      note: "",
      startingAddress: "",
      arrivalAddress: "",
      driver: undefined,
      customer: undefined,
    });
    if (eventDialog) {
      eventDialog();
    }
  };

  const content = {
    startingAddress: "Adresse de départ",
    arrivalAddress: "Adresse d'arrivée",
    addCustomer: "Ajouter un client",
  };

  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.addCustomer}
        main={<CustomerForm eventDialog={() => setOpen(false)} />}
      />
      <Box sx={{ width: "100%" }}>
        {/* -- RACE -- */}
        <SelectField
          name="type"
          options={COURSE_TYPE}
          error={errors}
          control={control}
          label="Type de course"
          defaultValue={edit ? race.type : ""}
        />
        <DateField
          name="dateTimeRace"
          error={errors}
          control={control}
          label="dateTimeRace"
          defaultValue={edit ? race.dateTimeRace : ""}
        />
        <Box sx={{ position: "relative", width: "100%" }}>
          <SelectField
            name="customerId"
            options={customersArray}
            error={errors}
            control={control}
            label="Client"
            shrink
            defaultValue={edit ? race.customer?.id : ""}
          />
          <Box sx={{ position: "absolute", right: 0, top: ".5rem", height: "3.5rem", display: "flex" }}>
            <MuiButton variant="contained" onClick={() => setOpen(true)}>
              <AddIcon />
            </MuiButton>
          </Box>
        </Box>
        <SelectField
          name="driverId"
          options={driverArray}
          error={errors}
          control={control}
          label="Chauffeur"
          shrink
          defaultValue={edit ? race.driver?.id : ""}
        />
        <InputField
          name="note"
          type="textarea"
          error={errors}
          control={control}
          label="Remarque"
          defaultValue={edit ? race.note : ""}
        />

        {edit && <Typography variant="h5">Adresse de départ:</Typography>}
        {edit && <Typography variant="body2">{race.startingAddress}</Typography>}
        <AutocompleteField
          label={edit ? "Modifier le départ" : content.startingAddress}
          error={errors}
          control={control}
          name="startingAddress"
        />

        {edit && <Typography variant="h5">Adresse d'arrivée:</Typography>}
        {edit && <Typography variant="body2">{race.arrivalAddress}</Typography>}
        <AutocompleteField
          label={edit ? "Modifier l'arrivée" : content.arrivalAddress}
          error={errors}
          control={control}
          name="arrivalAddress"
        />
      </Box>
      <Button label="Sauvegarder" eventClick={handleSubmit(handleRace)} margin="1rem 0" />
    </>
  );
};

export default RaceForm;
