import React from "react";
import useAuthReq from "../services/useAuthReq";
import { Typography } from "@mui/material";
import { AuthTemplate } from "../templates/AuthTemplate";
import Links from "../../Shared/atoms/Links";
import AuthForm from "../organisms/AuthForm";

const SignUp = () => {
  const { postAuthRequest } = useAuthReq("auth/signup");

  const content = {
    title: "INSCRIPTION",
    subTitle: "Bienvenue sur Becose Agenda",
    signIn: "Connectez-vous",
    signInMessage: " Vous avez déjà un compte ? ",
  };

  return (
    <AuthTemplate
      isSignUp
      title={content.title}
      subTitle={content.subTitle}
      main={<AuthForm signUp submitHandler={postAuthRequest} />}
      footer={
        <Typography fontSize="12px" textAlign="center">
          {content.signInMessage}
          <Links title={content.signIn} to="/connexion" textTransform="none" fontSize="12px" />
        </Typography>
      }
    />
  );
};

export default SignUp;
