import React, { FC } from "react";
import { Box, SxProps, Theme } from "@mui/material";

export interface ImageProps {
  image: string;
  sx?: SxProps<Theme> | undefined;
}

const Image: FC<ImageProps> = ({ image, sx }) => {
  return (
    <Box sx={sx}>
      <img src={image} alt="logo" style={{ width: "100%", height: "100%" }} />
    </Box>
  );
};

export default Image;
