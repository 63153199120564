import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { selectAuth } from "../../AuthProfile/services/authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { RaceStatus } from "../utils/_interface";
import useRaceReq from "./useRaceReq";

const useRaceStatusReq = () => {
  const dispatch = useDispatch();
  const [sendRequest] = useHttp();
  const { getRace } = useRaceReq();
  const { token, profileId } = useSelector(selectAuth);

  // edit status a race in the database
  const editStatusRace = useCallback((raceId: string, data: RaceStatus) => {
    const config = requestConfigBuilder("PUT", `race/profile=${profileId}/status/race=${raceId}`, token, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getRace(raceId);
      }
    });
  }, []);

  return { editStatusRace };
};

export default useRaceStatusReq;
