import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Shared/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectError, setError } from "../../Shared/services/errorReducer";
import useProfileReq from "../services/useProfileReq";
import { profileSchema } from "../../Shared/utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { InputField } from "../../Shared/molecules/fields/InputField";
import { selectProfile } from "../services/profileReducer";
import { Profile as ProfileInterface } from "../utils/_interface";
import { AutocompleteField } from "../../Shared/molecules/fields/AutocompleteMapsField";
import { ErrorProps } from "../../Shared/utils/_interface";
import { useNavigate } from "react-router-dom";

interface ProfileFormProps {
  edit?: boolean;
}

const ProfileForm: FC<ProfileFormProps> = ({ edit }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useSelector(selectError);
  const profile = useSelector(selectProfile);
  const { putProfileReq } = useProfileReq();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProfileInterface>({
    resolver: yupResolver(profileSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    return () => clearTimeout(timer);
  }, [type]);

  const submitHandler = (data) => {
    const body = { ...data };
    putProfileReq(body);
    reset({
      lastName: "",
      firstName: "",
      phone: "",
      note: "",
      address: "",
      account: { email: "", password: "" },
    });
    navigate(-1);
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        {/* -- Authenticate -- */}
        <InputField
          name="email"
          type="email"
          error={errors}
          control={control}
          label="Email"
          defaultValue={edit ? profile.account?.email : ""}
        />
        <InputField isPassword name="password" error={errors} control={control} label="Mot de passe" />
        {/* -- Profile -- */}
        <InputField
          name="lastName"
          error={errors}
          control={control}
          label="Nom"
          defaultValue={edit ? profile.lastName : ""}
        />
        <InputField
          name="firstName"
          error={errors}
          control={control}
          label="Prénom"
          defaultValue={edit ? profile.firstName : ""}
        />
        <InputField
          name="phone"
          type="tel"
          error={errors}
          control={control}
          label="Téléphone"
          defaultValue={edit ? profile.phone : ""}
        />
        <InputField
          name="note"
          type="textarea"
          error={errors}
          control={control}
          label="Remarque"
          defaultValue={edit ? profile.note : ""}
        />
        {edit && <Typography variant="h5">Adresse:</Typography>}
        {edit && <Typography variant="body2">{profile.address}</Typography>}
        <AutocompleteField
          label={edit ? "Modifier l'adresse" : "Adresse"}
          error={errors}
          control={control}
          name="address"
        />
      </Box>
      <Box justifySelf="start">
        <Button label="SAUVEGARDER LES MODIFICATIONS" eventClick={handleSubmit(submitHandler)} margin="1rem 0" />
      </Box>
    </Box>
  );
};

export default ProfileForm;
