import React, { FC } from "react";
import RaceTabsTemplate from "../templates/RaceTabsTemplate";
import { Box } from "@mui/material";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import { useNavigate } from "react-router-dom";
import { DrawerDriverTemplate } from "../../Shared/templates/DrawerDriverTemplate";

const content = {
  title: "Les Courses",
  back: "Retour",
};

const RacesDriverWrapper: FC = () => {
  const navigate = useNavigate();

  const handleAddRace = () => {
    navigate(-1);
  };

  return (
    <>
      <DrawerDriverTemplate
        main={
          <>
            <HeadInfoPage title={content.title} buttonText={content.back} buttonClick={handleAddRace} />
            <Box children={<RaceTabsTemplate />} />
          </>
        }
      />
    </>
  );
};

export default RacesDriverWrapper;
