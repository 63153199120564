import React, { ElementType, FC, InputHTMLAttributes, useState } from "react";
import * as hookForm from "react-hook-form";
import { darken, InputAdornment, InputBaseComponentProps, SxProps, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { colors } from "../../utils/constants";

const textFieldCustom = {
  "& .MuiOutlinedInput-root": {
    color: colors.black,
    "&:hover fieldset": {
      borderColor: colors.blue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.blue,
    },
  },
  label: {
    color: colors.black,
  },
};

interface InputFieldProps {
  control: hookForm.Control<any> | undefined;
  error: hookForm.FieldErrors;
  name: string;
  label?: string;
  defaultValue?: string | number;
  placeholder?: string;
  numberOfLigne?: number;
  disabled?: boolean;
  helperText?: string;
  isPassword?: boolean;
  type?: InputHTMLAttributes<unknown>["type"];
  inputComponent?: ElementType<InputBaseComponentProps>;
  shrink?: boolean;
  sx?: SxProps;
  margin?: string;
}

export const InputField: FC<InputFieldProps> = ({
  control,
  error,
  name,
  label,
  defaultValue,
  placeholder,
  isPassword,
  numberOfLigne,
  disabled,
  type,
  helperText,
  inputComponent,
  shrink,
  margin,
  sx,
}) => {
  const [hide, setHide] = useState(isPassword);
  const isMultiline = !!(numberOfLigne && numberOfLigne > 1);

  return (
    <hookForm.Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TextField
          sx={{ ...textFieldCustom, ...sx, margin: margin, width: "100%" }}
          inputRef={ref}
          disabled={disabled}
          multiline={isMultiline}
          rows={numberOfLigne}
          label={label}
          value={value}
          onBlur={onBlur}
          error={!!error[name]}
          onChange={(value1) => onChange(value1)}
          placeholder={placeholder}
          type={hide ? "password" : type}
          helperText={(error[name] && error[name]?.message) || helperText}
          InputLabelProps={{ shrink: shrink }}
          InputProps={{
            inputComponent: inputComponent,
            endAdornment: (
              <InputAdornment position="end">
                {isPassword ? (
                  <IconButton onClick={() => setHide(!hide)} edge="end">
                    {hide ? (
                      <VisibilityOffIcon sx={{ color: colors.gray }} />
                    ) : (
                      <VisibilityIcon sx={{ color: darken(colors.gray, 0.4) }} />
                    )}
                  </IconButton>
                ) : (
                  ""
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

InputField.defaultProps = {
  defaultValue: "",
  numberOfLigne: 1,
  type: "text",
  margin: ".5rem 0",
};
