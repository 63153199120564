import React, { FC, useEffect, useState } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { Box, Paper, styled, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import useCustomerReq from "../services/useCustomerReq";
import { useDispatch, useSelector } from "react-redux";
import { selectCustomer, setCustomer } from "../services/customerReducer";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../Shared/atoms/Button";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import CustomerForm from "../organisms/CustomerForm";
import { Profile } from "../../AuthProfile/utils/_interface";
import Dialog from "../../Shared/molecules/Dialog";
import useBreakpoints from "../../Shared/services/useBreakpoints";

const SpanCustom = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const CustomerDetails: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const customer = useSelector(selectCustomer);
  const { downSm, downMd } = useBreakpoints();
  const { getCustomer, deleteCustomer } = useCustomerReq();
  const [open, setOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const handleBack = () => {
    navigate(-1);
    const timer = setTimeout(() => {
      dispatch(setCustomer({} as Profile));
    }, 500);
    return () => clearTimeout(timer);
  };

  const handleDelete = () => {
    navigate(-1);
    if (customerId != null) {
      deleteCustomer(customerId);
    }
  };

  useEffect(() => {
    if (customerId != null) {
      getCustomer(customerId);
    } else {
      navigate("/client", { replace: true });
    }
  }, [open]);

  const content = {
    infoTitle: "Information:",
    updateCustomer: "Modifier le client",
    deleted: "Supprimé",
    deletedMessage: `Vous êtes sûr de vouloir supprimer le client ${customer.firstName} ${customer.lastName}? `,
    validate: "valider",
    cancel: "annuler",
    back: "Retour",
  };

  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.updateCustomer}
        main={<CustomerForm customerId={customerId} edit eventDialog={() => setOpen(false)} />}
      />
      <Dialog
        openDialog={confirmDialog}
        handleDialog={() => setConfirmDialog(false)}
        handleEvent={handleDelete}
        dialogTitle={content.deleted}
        dialogContentText={content.deletedMessage}
        DialogFirstButtonTitle={content.validate}
        DialogSecondButtonTitle={content.cancel}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage
              title={`${customer.firstName} ${customer.lastName}`}
              buttonText={content.back}
              buttonClick={handleBack}
            />
            <Paper elevation={3} sx={{ padding: "1rem" }}>
              <Box display="flex" flexDirection={downSm ? "column" : "row"}>
                <Typography
                  textAlign={downSm ? "center" : "start"}
                  variant={downSm ? "h5" : "h3"}
                  component="h3"
                  marginY="1rem"
                >
                  {content.infoTitle}
                </Typography>
                <Box display="flex" justifyContent="center">
                  <Button eventClick={() => setOpen(true)} label={<EditIcon />} variant="text" />
                  <Button
                    type="error"
                    eventClick={() => setConfirmDialog(true)}
                    label={<DeleteIcon />}
                    variant="text"
                  />
                </Box>
              </Box>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Adresse : </SpanCustom>
                {`${customer.address ? customer.address : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Téléphone : </SpanCustom>
                {customer.phone ? customer.phone : "..."}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Information : </SpanCustom>
                {`${customer.note ? customer.note : "..."}`}
              </Typography>
            </Paper>
          </Box>
        }
      />
    </>
  );
};

export default CustomerDetails;
