import React, { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Header from "../organisms/Header";
import { ListDrawer } from "../organisms/ListDrawer";
import useProfileReq from "../../AuthProfile/services/useProfileReq";
import useCustomerReq from "../../Customer/services/useCustomerReq";
import { Container } from "@mui/material";
import LoadedMapWrapper from "../organisms/LoadedMapWrapper";
import { useSelector } from "react-redux";
import { selectCompany } from "../../Company/services/companyReducer";
import useRaceReq from "../../Race/services/useRaceReq";

interface DrawerTemplateProps {
  main: JSX.Element;
  window?: () => Window;
}

export const DrawerTemplate: FC<DrawerTemplateProps> = ({ main, window }) => {
  const drawerWidth = 230;
  const { getRaces } = useRaceReq();
  const { getProfileReq } = useProfileReq();
  const { getCustomers } = useCustomerReq();
  const company = useSelector(selectCompany);

  const [mobileOpen, setMobileOpen] = useState(false);
  const container = window !== undefined ? () => window().document.body : undefined;

  useEffect(() => {
    getProfileReq();
    getCustomers();
    if (company.id) {
      getRaces(company.id);
    }
  }, [company.id]);

  return (
    <LoadedMapWrapper
      children={
        <Box sx={{ display: "flex" }}>
          <Header drawerWidth={drawerWidth} />
          <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { marginBottom: 0 } }}>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={() => setMobileOpen(!mobileOpen)}
              ModalProps={{ keepMounted: true }}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
            >
              <ListDrawer />
            </Drawer>
            <Drawer
              open
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" },
                "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
              }}
            >
              <ListDrawer />
            </Drawer>
          </Box>
          <Box component="main" sx={{ flexGrow: 1, width: { xs: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar sx={{ marginBottom: "1rem" }} />
            <Container maxWidth="lg">{main}</Container>
          </Box>
        </Box>
      }
    />
  );
};
