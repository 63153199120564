import React, { FC } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import RaceTabsTemplate from "../templates/RaceTabsTemplate";
import { Box } from "@mui/material";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import { useLocation, useNavigate } from "react-router-dom";
import RaceForm from "../organisms/RaceForm";

const content = {
  title: "Les Courses",
  addRace: "Ajouter une course",
  back: "Retour",
};

const RacesWrapper: FC = () => {
  useRedirectNotCompany();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAddRace = () => {
    if (location.pathname === "/course/nouvelle") {
      navigate(-1);
    } else {
      navigate("/course/nouvelle");
    }
  };

  return (
    <>
      <DrawerTemplate
        main={
          <>
            <HeadInfoPage
              title={content.title}
              buttonText={location.pathname === "/course/nouvelle" ? content.back : content.addRace}
              buttonClick={handleAddRace}
            />
            <Box children={location.pathname === "/course/nouvelle" ? <RaceForm /> : <RaceTabsTemplate />} />
          </>
        }
      />
    </>
  );
};

export default RacesWrapper;
