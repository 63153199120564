import React, { FC } from "react";
import Image from "../../Shared/atoms/Image";
import logo from "../../assets/logos/logo.svg";
import signInPicture from "../../assets/illustrations/signIn.svg";
import signUpPicture from "../../assets/illustrations/signUp.svg";
import successSignUpPicture from "../../assets/illustrations/successSignUp.svg";
import Title from "../../Shared/atoms/Title";
import { Box, Container } from "@mui/material";
import useBreakpoints from "../../Shared/services/useBreakpoints";

export interface AuthTemplateProps {
  title: string;
  main: JSX.Element;
  subTitle?: string;
  isSignUp?: boolean;
  isSuccessSignUp?: boolean;
  footer?: JSX.Element;
  footerHeight?: string;
}

export const AuthTemplate: FC<AuthTemplateProps> = ({
  main,
  footer,
  title,
  subTitle,
  isSignUp,
  isSuccessSignUp,
  footerHeight,
}) => {
  const { downMd, upMd } = useBreakpoints();

  return (
    <Container maxWidth="lg">
      <Box display="flex" height="99vh" alignItems="center" flexDirection={downMd ? "column" : "row"}>
        {downMd && <Image image={logo} sx={{ marginTop: "2rem", height: "45px", width: "165px" }} />}
        <Box display="flex" justifyContent="center" width={downMd ? "100%" : "63%"} height={downMd ? "27%" : "100%"}>
          <Image image={isSignUp ? signUpPicture : isSuccessSignUp ? successSignUpPicture : signInPicture} />
        </Box>
        <Box
          display="flex"
          width={downMd ? "100%" : "37%"}
          height={downMd ? "73%" : "100%"}
          flexDirection="column"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {upMd && <Image image={logo} sx={{ height: "73px", width: "165px" }} />}
          <Title
            title={title}
            component="h1"
            sxTitle={{ fontSize: "20px", fontWeight: 700, marginBottom: "1rem" }}
            subTitle={subTitle}
            sxSubTitle={{ fontSize: "16px" }}
          />
          {main}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            height={footerHeight}
          >
            {footer}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

AuthTemplate.defaultProps = {
  footerHeight: "10vh",
};
