import React from "react";
import useAuthReq from "../services/useAuthReq";
import { AuthTemplate } from "../templates/AuthTemplate";
import AuthForm from "../organisms/AuthForm";
import { Typography } from "@mui/material";
import Links from "../../Shared/atoms/Links";

const ForgotPassword = () => {
  const { putForgotPasswordRequest } = useAuthReq("auth/forgot-password");

  const content = {
    title: "MOT DE PASSE OUBLIÉ",
    subTitle:
      "Vous êtes sur le point de faire une demande de réinitialisation de votre mot de passe. Un e-mail de réinitialisation sera envoyé à l'adresse e-mail que vous avez saisie.",
    backMessage: "Retourner à la page de ",
    back: "connexion.",
  };

  return (
    <AuthTemplate
      title={content.title}
      subTitle={content.subTitle}
      main={<AuthForm forgotPassword submitHandler={putForgotPasswordRequest} />}
      footer={
        <Typography fontSize="12px" textAlign="center">
          {content.backMessage}
          <Links title={content.back} to="/connexion" textTransform="none" fontSize="12px" />
        </Typography>
      }
    />
  );
};

export default ForgotPassword;
