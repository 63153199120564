import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Shared/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { Box } from "@mui/material";
import { InputField } from "../../Shared/molecules/fields/InputField";
import { selectCompany } from "../../Company/services/companyReducer";
import { selectVehicle } from "../store/vehicleReducer";
import useVehicleReq from "../hooks/useVehicleReq";
import { DateField } from "../../Shared/molecules/fields/DateField";
import { Vehicle } from "../utils/_interface";
import { SelectField } from "../../Shared/molecules/fields/SelectField";
import { COLORS_VEHICLE, ENGINE_VEHICLE, NUMBER_OF_SEATS, TYPE_VEHICLE } from "../utils/constants";
import { ErrorProps } from "../../Shared/utils/_interface";

interface DriverFormProps {
  eventDialog?: () => void;
  vehicleId?: string;
  edit?: boolean;
}

const VehicleForm: FC<DriverFormProps> = ({ eventDialog, vehicleId, edit }) => {
  const dispatch = useDispatch();
  const { type } = useSelector(selectError);
  const company = useSelector(selectCompany);
  const vehicle = useSelector(selectVehicle);
  const { addVehicle, putVehicle } = useVehicleReq();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Vehicle>({
    // resolver: yupResolver(profileSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  const submitHandler = (data) => {
    const body: Vehicle = { ...data };

    if (edit) {
      if (vehicleId != null) {
        if (company.id != null) {
          putVehicle(company.id, vehicleId, body);
        }
      }
    } else {
      if (company.id != null) {
        addVehicle(company.id, body);
      }
    }

    reset({
      type: "",
      brand: "",
      model: "",
      engine: "",
      nbSeat: "",
      color: "",
      licencePlate: "",
      taxiLicence: "",
    });

    if (eventDialog) {
      eventDialog();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    return () => clearTimeout(timer);
  }, [type]);

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        {/* -- VEHICLES -- */}
        <SelectField
          name="type"
          options={TYPE_VEHICLE}
          error={errors}
          control={control}
          label="type"
          defaultValue={edit ? vehicle.type : ""}
        />
        <InputField
          name="brand"
          error={errors}
          control={control}
          label="brand"
          placeholder="Peugeot"
          defaultValue={edit ? vehicle.brand : ""}
        />
        <InputField
          name="model"
          error={errors}
          control={control}
          label="model"
          placeholder="508"
          defaultValue={edit ? vehicle?.model : ""}
        />
        <SelectField
          name="engine"
          options={ENGINE_VEHICLE}
          error={errors}
          control={control}
          label="engine"
          defaultValue={edit ? vehicle.engine : ""}
        />
        <SelectField
          name="nbSeat"
          options={NUMBER_OF_SEATS}
          error={errors}
          control={control}
          label="nbSeat"
          defaultValue={edit ? vehicle.nbSeat : ""}
        />
        <SelectField
          name="color"
          options={COLORS_VEHICLE}
          error={errors}
          control={control}
          label="color"
          defaultValue={edit ? vehicle.color : ""}
        />
        <InputField
          name="licencePlate"
          error={errors}
          control={control}
          label="licencePlate"
          placeholder="123..."
          defaultValue={edit ? vehicle.licencePlate : ""}
        />
        <InputField
          name="taxiLicence"
          error={errors}
          control={control}
          label="taxiLicence"
          placeholder="123..."
          defaultValue={edit ? vehicle.taxiLicence : ""}
        />
        <DateField
          name="nextTechnicalControl"
          error={errors}
          control={control}
          label="nextTechnicalControl"
          // defaultValue={edit ? vehicle.nextTechnicalControl : ''}
        />
      </Box>
      <Box justifySelf="start">
        <Button label="Sauvegarder" eventClick={handleSubmit(submitHandler)} margin="1rem 0" />
      </Box>
    </Box>
  );
};

export default VehicleForm;
