import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Company } from "../utils/_interface";

const company: Company = {} as Company;

export const setCompany = createAction<Company>("setCompany");

const companyReducer = createReducer(company, (builder) =>
  builder.addCase(setCompany, (state, action: PayloadAction<Company>) => {
    return action.payload;
  }),
);

export const selectCompany = (state: RootState) => state.company;
export default companyReducer;
