import React, { FC, MouseEvent } from "react";
import { Link } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../utils/constants";
import { TextAlign, TextTransform } from "../utils/types";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: colors.blue,
    cursor: "pointer",
    paddingTop: "4px",
  },
});

export interface LinksProps {
  title: string | JSX.Element;
  to: string;
  fontSize?: string;
  eventClick?: (e: MouseEvent) => void;
  textAlign?: TextAlign;
  textTransform?: TextTransform;
  verticalAlign?: string;
}

const Links: FC<LinksProps> = ({ title, to, textAlign, eventClick, verticalAlign, fontSize, textTransform }) => {
  const classes = useStyles();

  return (
    <Link
      className={classes.link}
      to={to}
      style={{ fontSize: fontSize, textTransform: textTransform, textAlign: textAlign, verticalAlign: verticalAlign }}
      onClick={eventClick}
    >
      {title}
    </Link>
  );
};

Links.defaultProps = {
  textAlign: "center",
  fontSize: "12px",
  textTransform: "uppercase",
};

export default Links;
