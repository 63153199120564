/** @jsxImportSource @emotion/react */
import React, { FC, ReactNode } from "react";
import { Button as MuiButton, darken, lighten } from "@mui/material";
import { ButtonType, ButtonVariant, StatusType } from "../utils/types";
import { colors } from "../utils/constants";
import { css, Keyframes } from "@emotion/react";
import { getColorStatus } from "../../Race/utils/handleRace";

interface ButtonProps {
  label: string | ReactNode;
  eventClick?: () => void;
  width?: string | number;
  padding?: string | number;
  margin?: string | number;
  type?: ButtonType;
  variant?: ButtonVariant;
  disabled?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  animation?: Keyframes;
  statusRace?: StatusType;
}

const Button: FC<ButtonProps> = ({
  label,
  eventClick,
  padding,
  margin,
  disabled,
  variant,
  startIcon,
  endIcon,
  type,
  width,
  animation,
  statusRace,
}) => {
  // const styleAnimated = styled()(({ theme }) => ({
  //   transition: theme.transitions.create(['transform'], {
  //     duration: theme.transitions.duration.standard,
  //   }),
  //   '&:hover': { transform: 'scale(1.3)' },
  // }))

  const styleContained = (background) => ({
    color: colors.white,
    backgroundColor: background,
    "&:hover": { backgroundColor: darken(background, 0.2) },
  });

  const styleRaceStatus = (background) => ({
    color: colors.white,
    backgroundColor: background,
    "&:hover": { backgroundColor: darken(background, 0.2) },
  });

  const styleText = (color) => ({
    color: color,
    backgroundColor: "rgba(0, 0, 0, 0)",
    "&:hover": {
      color: darken(color, 0.2),
      backgroundColor: lighten(color, 0.9),
    },
  });

  const styleOutlined = (color) => ({
    color: color,
    borderColor: color,
    backgroundColor: "rgba(0, 0, 0, 0)",
    "&:hover": {
      borderColor: color,
      backgroundColor: lighten(color, 0.9),
    },
  });

  const defaultButtonStyle = [
    {
      borderRadius: 20,
      backgroundColor: colors.blue,
      width: width,
      padding: padding,
      margin: margin,
    },
    // STYLE CONTAINED
    type === "statusType" && { ...styleRaceStatus(getColorStatus(statusRace)) },
    type === "error" && { ...styleContained(colors.error) },
    type === "warning" && { ...styleContained(colors.warning) },
    type === "success" && { ...styleContained(colors.success) },
    type === "info" && { ...styleContained(colors.skyBlue) },
    type === "dark" && { ...styleContained(colors.navyBlue) },
    // STYLE TEXT
    variant === "text" && { ...styleText(colors.blue) },
    type === "error" && variant === "text" && { ...styleText(colors.error) },
    type === "warning" && variant === "text" && { ...styleText(colors.warning) },
    type === "success" && variant === "text" && { ...styleText(colors.success) },
    type === "info" && variant === "text" && { ...styleText(colors.skyBlue) },
    type === "light" && variant === "text" && { ...styleText(colors.white) },
    type === "dark" && variant === "text" && { ...styleText(colors.navyBlue) },
    // STYLE OUTLINED
    variant === "outlined" && { ...styleOutlined(colors.blue) },
    type === "info" && variant === "outlined" && { ...styleOutlined(colors.skyBlue) },
    type === "error" && variant === "outlined" && { ...styleOutlined(colors.error) },
    type === "warning" && variant === "outlined" && { ...styleOutlined(colors.warning) },
    type === "success" && variant === "outlined" && { ...styleOutlined(colors.success) },
    type === "dark" && variant === "outlined" && { ...styleOutlined(colors.navyBlue) },
  ];

  return (
    <MuiButton
      sx={defaultButtonStyle}
      disabled={disabled}
      variant={variant}
      onClick={eventClick}
      startIcon={startIcon}
      endIcon={endIcon}
      css={css`
        animation: ${animation} 400ms ease-in-out;
      `}
    >
      {label}
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: "contained",
  padding: ".3rem .5rem",
  margin: "0rem .5rem",
};

export default Button;
