/** @jsxImportSource @emotion/react */
import React, { useEffect } from "react";
import useAuthReq from "../services/useAuthReq";
import { Box, Typography } from "@mui/material";
import { AuthTemplate } from "../templates/AuthTemplate";
import Links from "../../Shared/atoms/Links";
import { css } from "@emotion/react";
import { fadeIn } from "../../Shared/utils/keyframesStyle";
import { InputField } from "../../Shared/molecules/fields/InputField";
import Button from "../../Shared/atoms/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordSchema } from "../../Shared/utils/validation";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAccount } from "../services/accountReducer";

const ResetPassword = () => {
  const { email, token } = useParams();
  const navigate = useNavigate();
  const { resetLink } = useSelector(selectAccount);
  const { getAccountRequest } = useAuthReq();
  const { putResetPasswordRequest } = useAuthReq();

  useEffect(() => {
    if (email != null) {
      getAccountRequest(email);
    }
  }, []);

  const handleResetPassword = (data) => {
    const body = {
      ...data,
      resetLink: token,
    };
    if (token) {
      putResetPasswordRequest(body);
      navigate("/connexion");
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  const content = {
    labelPassword: "Mot de passe",
    labelConfirmPassword: "Confirmation du mot de passe",
    buttonEnd: "Terminer",
    title: "RÉINITIALISER LE MOT DE PASSE",
    subTitle: "Entrer votre nouveau mot de passe.",
    backMessage: "Retourner à la page de ",
    forbiddenMessage:
      "Les informations que vous nous avez fournies ne vous permettent pas de réinitialiser votre mot de passe, veuillez  renouveler la demande.",
    back: "connexion.",
  };

  return (
    <AuthTemplate
      isSignUp
      title={content.title}
      subTitle={content.subTitle}
      main={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          css={css`
            animation: ${fadeIn} 1s ease-in-out;
          `}
        >
          {token === resetLink ? (
            <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center" width="100%">
              <InputField
                isPassword
                name="password"
                error={errors}
                control={control}
                margin="1rem 0"
                placeholder={content.labelPassword}
              />
              <InputField
                isPassword
                name="confirmPassword"
                error={errors}
                control={control}
                margin="1rem 0"
                placeholder={content.labelConfirmPassword}
              />
              <Button label={"Envoyer"} eventClick={handleSubmit(handleResetPassword)} />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center" width="100%">
              <Typography fontSize="12px" textAlign="center">
                {content.forbiddenMessage}
              </Typography>
            </Box>
          )}
        </Box>
      }
      footer={
        <Typography fontSize="12px" textAlign="center">
          {content.backMessage}
          <Links title={content.back} to="/connexion" textTransform="none" fontSize="12px" />
        </Typography>
      }
    />
  );
};

export default ResetPassword;
