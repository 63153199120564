import React, { FC } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Shared/atoms/Button";
import { Box } from "@mui/material";
import { InputField } from "../../Shared/molecules/fields/InputField";
import { useNavigate } from "react-router-dom";
import { RadioButtonGroup } from "../../Shared/molecules/RadioButtonGroup";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";
import { errorsMessage } from "../../Shared/utils/validation";
import useRaceStatusReq from "../services/useStatusReq";
import { useSelector } from "react-redux";
import { selectProfile } from "../../AuthProfile/services/profileReducer";

interface RaceFormProps {
  eventDialog?: () => void;
  raceId?: string;
}

export const CauseCancellationForm: FC<RaceFormProps> = ({ eventDialog, raceId }) => {
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const { editStatusRace } = useRaceStatusReq();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(object().shape({ cancellation: string().required(errorsMessage.requiredMsg).trim() })),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  const handleCancellation = (cancellation) => {
    const data: any = {
      type: "cancelled",
      firstName: profile.firstName,
      lastName: profile.lastName,
    };
    const body = { ...data, ...cancellation };

    if (raceId != null) {
      editStatusRace(raceId, body);
    }

    if (eventDialog) {
      eventDialog();
      navigate(-1);
    }
  };

  const content = {
    noShow: "Le client ne s'est pas présenté ",
    customerRequest: "A la demande du client",
    byAdmin: "Annulation par l'admin",
    postComment: "Laisser un commentaire",
  };

  const choices = [
    { key: content.noShow, label: content.noShow },
    { key: content.customerRequest, label: content.customerRequest },
  ];

  const adminChoices = [
    { key: content.noShow, label: content.noShow },
    { key: content.customerRequest, label: content.customerRequest },
    { key: content.byAdmin, label: content.byAdmin },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <RadioButtonGroup
          control={control}
          choices={profile.account?.accountTypeId === 1 ? adminChoices : choices}
          name="cancellation"
        />
        <InputField name="cancellation" error={errors} control={control} label={content.postComment} />
        <Button label="Sauvegarder" eventClick={handleSubmit(handleCancellation)} margin="1rem 0" />
      </Box>
    </>
  );
};
