import { useCallback } from "react";
import { selectAuth } from "../../AuthProfile/services/authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { setCompany } from "./companyReducer";
import useProfileReq from "../../AuthProfile/services/useProfileReq";
import { Company } from "../utils/_interface";

const useCompanyReq = () => {
  const dispatch = useDispatch();
  const { token, profileId } = useSelector(selectAuth);
  const [sendRequest] = useHttp();
  const { getProfileReq } = useProfileReq();

  // Get a company from the database
  const getCompany = useCallback((companyId: number) => {
    const config = requestConfigBuilder("GET", `company/profile=${profileId}/company=${companyId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        dispatch(setCompany(response.company));
      }
    });
  }, []);

  // Get a company from the database
  const getCompanyDriver = useCallback((companyId: number) => {
    const config = requestConfigBuilder("GET", `company/profile=${profileId}/driver/company=${companyId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        dispatch(setCompany(response.company));
      }
    });
  }, []);

  // add a company to the database
  const postCompany = useCallback((company: Company) => {
    const config = requestConfigBuilder("POST", `company/profile=${profileId}/new`, token, company);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getProfileReq();
      }
    });
  }, []);

  return { getCompany, getCompanyDriver, postCompany };
};

export default useCompanyReq;
