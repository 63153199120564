import React, { FC } from "react";
import * as hookForm from "react-hook-form";
import { FieldErrors } from "react-hook-form";
import { TextField } from "@mui/material";
import { colors } from "../../utils/constants";

const textFieldCustom = {
  "& .MuiOutlinedInput-root": {
    color: colors.black,
    "&:hover fieldset": {
      borderColor: colors.blue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.blue,
    },
  },
  label: {
    color: colors.black,
  },
};

export interface SelectFieldProps {
  options: { key: string | number; label: string }[];
  control: hookForm.Control<any> | undefined;
  error: FieldErrors;
  name: string;
  defaultValue?: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  helperText?: string;
  margin?: string;
  shrink?: boolean;
}

export const SelectField: FC<SelectFieldProps> = ({
  control,
  helperText,
  error,
  name,
  label,
  placeholder,
  defaultValue,
  disabled,
  options,
  margin,
  shrink,
}) => {
  return (
    <hookForm.Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <TextField
          sx={{ ...textFieldCustom, margin: margin, width: "100%" }}
          select
          disabled={disabled}
          inputRef={ref}
          placeholder={placeholder}
          value={value}
          label={label}
          onBlur={onBlur}
          error={!!error[name]}
          helperText={(error[name] && error[name]?.message) || helperText}
          onChange={(newValue) => onChange(newValue)}
          variant="outlined"
          InputLabelProps={{ shrink: shrink }}
          SelectProps={{
            native: true,
          }}
        >
          <option aria-label="None" value="" />
          {options.map((option) => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </TextField>
      )}
    />
  );
};

SelectField.defaultProps = {
  defaultValue: "",
  margin: ".5rem 0",
};
