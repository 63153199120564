/** @jsxImportSource @emotion/react */
import React, { FC, Fragment, useRef, useState } from "react";
import { ButtonGroup, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { StatusType } from "../../Shared/utils/types";
import { fadeIn } from "../../Shared/utils/keyframesStyle";
import useRaceStatusReq from "../services/useStatusReq";
import { RaceStatus } from "../utils/_interface";
import { useSelector } from "react-redux";
import { selectProfile } from "../../AuthProfile/services/profileReducer";
import Button from "../../Shared/atoms/Button";
import { getLabelStatus, handleChangeStatusMessage } from "../utils/handleRace";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import { CauseCancellationForm } from "./CauseCancellationForm";
import useBreakpoints from "../../Shared/services/useBreakpoints";

export interface ManagementDriverStatusRaceProps {
  raceId: string;
  currentStatus?: StatusType;
}

export const ManagementDriverStatusRace: FC<ManagementDriverStatusRaceProps> = ({ raceId, currentStatus }) => {
  const ref = useRef(null);
  const profile = useSelector(selectProfile);
  const { editStatusRace } = useRaceStatusReq();
  const { downSm } = useBreakpoints();
  const [open, setOpen] = useState(false);

  const handleStatus = (selectedStatus: StatusType) => {
    const data: RaceStatus = {
      type: selectedStatus,
      firstName: profile.firstName,
      lastName: profile.lastName,
    };
    editStatusRace(raceId, data);
  };

  const content = {
    causeCancel: "Cause de l'annulation",
  };

  const handleStatusType = () => {
    switch (currentStatus) {
      case "unassigned":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="accept"
              label={getLabelStatus("accept")}
              eventClick={() => handleStatus("accept")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="denied"
              label={getLabelStatus("denied")}
              eventClick={() => handleStatus("denied")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="cancelled"
              label={getLabelStatus("cancelled")}
              eventClick={() => setOpen(true)}
            />
          </ButtonGroup>
        );
      case "assigned":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="accept"
              label={getLabelStatus("accept")}
              eventClick={() => handleStatus("accept")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="denied"
              label={getLabelStatus("denied")}
              eventClick={() => handleStatus("denied")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="cancelled"
              label={getLabelStatus("cancelled")}
              eventClick={() => setOpen(true)}
            />
          </ButtonGroup>
        );
      case "accept":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="approaching"
              label={getLabelStatus("approaching")}
              eventClick={() => handleStatus("approaching")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="cancelled"
              label={getLabelStatus("cancelled")}
              eventClick={() => setOpen(true)}
            />
          </ButtonGroup>
        );
      case "denied":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="accept"
              label={getLabelStatus("accept")}
              eventClick={() => handleStatus("accept")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="cancelled"
              label={getLabelStatus("cancelled")}
              eventClick={() => setOpen(true)}
            />
          </ButtonGroup>
        );
      case "approaching":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="waiting"
              label={getLabelStatus("waiting")}
              eventClick={() => handleStatus("waiting")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="cancelled"
              label={getLabelStatus("cancelled")}
              eventClick={() => setOpen(true)}
            />
          </ButtonGroup>
        );
      case "waiting":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="started"
              label={getLabelStatus("started")}
              eventClick={() => handleStatus("started")}
            />
            <Button
              margin={0}
              type="statusType"
              statusRace="cancelled"
              label={getLabelStatus("cancelled")}
              eventClick={() => setOpen(true)}
            />
          </ButtonGroup>
        );
      case "started":
        return (
          <ButtonGroup variant="text">
            <Button
              margin={0}
              type="statusType"
              statusRace="completed"
              label={getLabelStatus("completed")}
              eventClick={() => handleStatus("completed")}
            />
          </ButtonGroup>
        );
    }
  };

  return (
    <Fragment>
      <DialogTemplate
        open={open}
        fullScreen={downSm}
        handleDialog={() => setOpen(false)}
        title={content.causeCancel}
        main={<CauseCancellationForm raceId={raceId} eventDialog={() => setOpen(false)} />}
      />
      <Typography
        variant="body2"
        paddingX="0.4rem"
        margin="0.3rem"
        ref={ref}
        css={css`
          animation: ${fadeIn} 0.3s ease-out;
        `}
      >
        {handleChangeStatusMessage(currentStatus)}
      </Typography>
      {handleStatusType()}
    </Fragment>
  );
};
