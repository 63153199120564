import { useCallback } from "react";
import { selectAuth } from "./authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { setProfile } from "./profileReducer";
import { Profile } from "../utils/_interface";

const useProfileReq = () => {
  const dispatch = useDispatch();
  const { token, profileId } = useSelector(selectAuth);
  const [sendRequest] = useHttp();

  const getProfileReq = useCallback(() => {
    const config = requestConfigBuilder("GET", `profile/id=${profileId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { profile } = response;
        dispatch(setProfile(profile));
      }
    });
  }, []);

  const putProfileReq = useCallback((data: Profile) => {
    const config = requestConfigBuilder("PUT", `profile/edit/id=${profileId}`, token, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getProfileReq();
      }
    });
  }, []);

  return { getProfileReq, putProfileReq };
};

export default useProfileReq;
