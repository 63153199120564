import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Race } from "../utils/_interface";
import { selectRaces } from "../services/racesReducer";
import TableRacesTemplate from "../templates/TableRacesTemplate";

const TableRacesFinish: FC = () => {
  const races = useSelector(selectRaces);
  const [racesRows, setRacesRows] = useState<Race[]>([]);

  useEffect(() => {
    if (Array.isArray(races) && races.length) {
      const rowsCache: any = [];

      races.forEach((race) => {
        if (race.lastStatus === "completed") {
          const dateTime = `${new Date(race.dateTimeRace!).toLocaleString()}`;
          rowsCache.push({
            raceId: race.id,
            statusRace: race.lastStatus,
            dateTimeRace: race.dateTimeRace ? dateTime : "",
            customer: `${race.customer?.firstName} ${race.customer?.lastName}`,
            startingAddress: race.startingAddress,
            arrivalAddress: race.arrivalAddress,
            driver: `${race.driver?.firstName} ${race.driver?.lastName}`,
          });
        }
      });
      setRacesRows(rowsCache);
    }
  }, [races]);

  return <TableRacesTemplate racesRows={racesRows} />;
};

export default TableRacesFinish;
