import React, { FC, useState } from "react";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useCompanyReq from "../../Company/services/useCompanyReq";
import { colors } from "../utils/constants";

interface DropdownMenuCompanyProps {
  title: string;
  options: { key: string | number; label: string }[];
  arrowColors?: string;
}

const DropdownMenuCompany: FC<DropdownMenuCompanyProps> = ({ options, title, arrowColors }) => {
  const { getCompany } = useCompanyReq();
  const [isOpenDropdown, setIsOpenDropdown] = useState<null | HTMLElement>(null);
  const open = Boolean(isOpenDropdown);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpenDropdown(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setIsOpenDropdown(null);
  };

  const handleSubmit = (companyId: number) => {
    getCompany(companyId);
    handleCloseDropdown();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      margin="2rem 0 2rem 1.3rem"
    >
      <Typography fontWeight="bold" variant="body2">
        {title}
      </Typography>
      <Button sx={{ color: arrowColors }} onClick={handleOpenDropdown}>
        {isOpenDropdown ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>
      <Menu anchorEl={isOpenDropdown} open={open} onClose={handleCloseDropdown}>
        {options.map((option) => {
          return (
            <MenuItem onClick={handleSubmit.bind(this, option.key as number)} key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

DropdownMenuCompany.defaultProps = {
  arrowColors: colors.blue,
};

export default DropdownMenuCompany;
