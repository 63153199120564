import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Shared/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { profileSchema } from "../../Shared/utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { InputField } from "../../Shared/molecules/fields/InputField";
import { Profile as ProfileInterface } from "../../AuthProfile/utils/_interface";
import { selectDriver } from "../services/driverReducer";
import useDriverReq from "../services/useDriverReq";
import { selectCompany } from "../../Company/services/companyReducer";
import { AutocompleteField } from "../../Shared/molecules/fields/AutocompleteMapsField";
import { ErrorProps } from "../../Shared/utils/_interface";

interface DriverFormProps {
  eventDialog?: () => void;
  driverId?: string;
  edit?: boolean;
}

const DriverForm: FC<DriverFormProps> = ({ eventDialog, driverId, edit }) => {
  const dispatch = useDispatch();
  const { type } = useSelector(selectError);
  const driver = useSelector(selectDriver);
  const company = useSelector(selectCompany);
  const { addDriver, putDriver } = useDriverReq();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProfileInterface>({
    resolver: yupResolver(profileSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    return () => clearTimeout(timer);
  }, [type]);

  const handleDriver = (data) => {
    const body = { ...data };
    if (edit) {
      if (driverId != null) {
        if (company.id != null) {
          putDriver(company.id, driverId, body);
        }
      }
    } else {
      if (company.id != null) {
        addDriver(company.id, body);
      }
    }

    reset({
      lastName: "",
      firstName: "",
      phone: "",
      note: "",
      address: "",
      account: { email: "", password: "" },
    });
    if (eventDialog) {
      eventDialog();
    }
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        {/* -- AUTH DRIVER -- */}
        <InputField
          name="email"
          type="email"
          error={errors}
          control={control}
          label="Email"
          defaultValue={edit ? driver.account?.email : ""}
        />
        {/* -- PROFILE DRIVER -- */}
        <InputField
          name="lastName"
          error={errors}
          control={control}
          label="Nom"
          defaultValue={edit ? driver.lastName : ""}
        />
        <InputField
          name="firstName"
          error={errors}
          control={control}
          label="Prénom"
          defaultValue={edit ? driver.firstName : ""}
        />
        <InputField
          name="phone"
          type="tel"
          error={errors}
          control={control}
          label="Téléphone"
          defaultValue={edit ? driver.phone : ""}
        />
        <InputField
          name="note"
          type="textarea"
          error={errors}
          control={control}
          label="Remarque"
          defaultValue={edit ? driver.note : ""}
        />
        {edit && <Typography variant="h5">Adresse:</Typography>}
        {edit && <Typography variant="body2">{driver.address}</Typography>}
        <AutocompleteField label={edit ? "" : "Adresse"} error={errors} control={control} name="address" />
      </Box>
      <Box justifySelf="start">
        <Button label="Sauvegarder" eventClick={handleSubmit(handleDriver)} margin="1rem 0" />
      </Box>
    </Box>
  );
};

export default DriverForm;
