import React, { FC, Fragment, useState } from "react";
import { IconButton, SwipeableDrawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ListDrawer } from "./ListDrawer";
import { ListDrawerDriver } from "./ListDrawerDriver";

interface MenuDrawerProps {
  isAdmin: boolean;
}

const MenuDrawer: FC<MenuDrawerProps> = ({ isAdmin }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }
    setIsOpenDrawer(open);
  };

  return (
    <Fragment>
      <IconButton
        sx={{ display: { xs: "flex", md: "none" } }}
        size="medium"
        onClick={toggleDrawer(true)}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        open={isOpenDrawer}
        anchor="left"
        disableDiscovery={iOS}
        disableBackdropTransition={!iOS}
        onOpen={toggleDrawer(true)}
        onClose={toggleDrawer(false)}
        sx={{ height: "100vh" }}
      >
        {isAdmin ? <ListDrawer /> : <ListDrawerDriver />}
      </SwipeableDrawer>
    </Fragment>
  );
};

export default MenuDrawer;
