import React, { FC, useState } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { Box } from "@mui/material";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import { DriversAccordionInfo } from "../organisms/DriversAccordionInfo";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import DriverForm from "../organisms/DriverForm";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";
import useBreakpoints from "../../Shared/services/useBreakpoints";

const Drivers: FC = () => {
  const [open, setOpen] = useState(false);
  const { downMd } = useBreakpoints();
  useRedirectNotCompany();

  const content = {
    title: "Mes chauffeurs",
    addDriver: "Ajouter un chauffeur",
  };
  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.addDriver}
        main={<DriverForm eventDialog={() => setOpen(false)} />}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage title={content.title} buttonText={content.addDriver} buttonClick={() => setOpen(true)} />
            <Box display="flex" flexDirection="column" width="100%">
              <DriversAccordionInfo />
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default Drivers;
