import React, { FC, useState } from "react";
import { DrawerTemplate } from "../templates/DrawerTemplate";
import { Box } from "@mui/material";
import Title from "../atoms/Title";
import DashboardSection from "../organisms/DashboardSection";
import { useSelector } from "react-redux";
import { selectCompany } from "../../Company/services/companyReducer";
import { selectCustomers } from "../../Customer/services/customersReducer";
import { CardContainer } from "../molecules/CardContainer";
import DialogTemplate from "../templates/DialogTemplate";
import CustomerForm from "../../Customer/organisms/CustomerForm";
import { useNavigate } from "react-router-dom";
import DriverForm from "../../Driver/organisms/DriverForm";
import VehicleForm from "../../Vehicle/organisms/VehicleForm";
import { useSnackbar } from "notistack";
import RaceForm from "../../Race/organisms/RaceForm";
import { selectRaces } from "../../Race/services/racesReducer";
import { getColorStatus } from "../../Race/utils/handleRace";
import useBreakpoints from "../services/useBreakpoints";

const Dashboard: FC = () => {
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const races = useSelector(selectRaces);
  const customers = useSelector(selectCustomers);
  const { downMd } = useBreakpoints();
  const [addType, setAddType] = useState("");
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (type: string) => {
    if (company.id) {
      setAddType(type);
      setOpen(true);
    } else {
      return enqueueSnackbar(`Veuillez choisir une société`, { variant: "info" });
    }
  };

  const handleFormDialog = () => {
    switch (addType) {
      case "race":
        return <RaceForm eventDialog={() => setOpen(false)} />;
      case "driver":
        return <DriverForm eventDialog={() => setOpen(false)} />;
      case "vehicle":
        return <VehicleForm eventDialog={() => setOpen(false)} />;
      case "customer":
        return <CustomerForm eventDialog={() => setOpen(false)} />;
    }
  };

  const handleTitleDialog = () => {
    switch (addType) {
      case "race":
        return "Ajouter une course";
      case "driver":
        return "Ajouter un chauffeur";
      case "vehicle":
        return "Ajouter un véhicule";
      case "customer":
        return "Ajouter un client";
      default:
        return "";
    }
  };

  const content = {
    title: "Tableau de bord",

    labelRaces: "Les courses",
    addRace: "Ajouter une course",
    emptyRace: "Pas de course ajoutée",

    labelCustomers: "Mes clients",
    addCustomers: "Ajouter un client",
    emptyCustomers: "Pas de client ajouté",

    labelDrivers: "Mes chauffeurs",
    addDrivers: "Ajouter un chauffeur",
    emptyDriver: "Pas de chauffeur ajouté",

    labelVehicles: "Mes véhicules",
    addVehicles: "Ajouter un véhicule",
    emptyVehicles: "Pas de véhicule ajouté",
  };

  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={handleTitleDialog()}
        main={handleFormDialog()}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <Title
              title={content.title}
              component="h1"
              variant="h4"
              textAlign="left"
              fontWeight="bold"
              sxTitle={{ marginBottom: "2rem" }}
            />
            <Box display="flex" flexDirection="column">
              <DashboardSection
                title={content.labelRaces}
                addCardLabel={content.addRace}
                emptyCardLabel={content.emptyRace}
                sectionInfo={races}
                seeMorRoute="/course/a-venir"
                addOnClick={() => navigate("/course/nouvelle")}
                cardBuilder={races
                  .filter((race) => race.lastStatus !== "completed" && race.lastStatus !== "cancelled")
                  .slice(0, 3)
                  .map((race) => {
                    const startingAddress = `Départ: ${race.startingAddress}`;
                    const arrivalAddress = `Arriver: ${race.arrivalAddress}`;
                    return (
                      <CardContainer
                        race
                        key={race.id}
                        colorsStatus={getColorStatus(race.lastStatus)}
                        title={new Date(race.dateTimeRace!).toLocaleString()}
                        subTitle={startingAddress}
                        secondSubtitle={arrivalAddress}
                        onClick={() => navigate(`/course/detail/${race.id}`, { state: { raceId: race.id } })}
                      />
                    );
                  })}
              />
              <DashboardSection
                title={content.labelCustomers}
                emptyCardLabel={content.emptyCustomers}
                addCardLabel={content.addCustomers}
                sectionInfo={customers}
                seeMorRoute="/client"
                addOnClick={() => handleOpen("customer")}
                cardBuilder={customers.slice(0, 3).map((customer) => {
                  return (
                    <CardContainer
                      key={customer.id}
                      title={`${customer.firstName} ${customer.lastName}`}
                      subTitle={customer.phone}
                      onClick={() => navigate(`/client/detail/${customer.id}`, { state: { customerId: customer.id } })}
                    />
                  );
                })}
              />
              <DashboardSection
                title={content.labelDrivers}
                addCardLabel={content.addDrivers}
                emptyCardLabel={content.emptyDriver}
                sectionInfo={company.races}
                seeMorRoute="/chauffeur"
                addOnClick={() => handleOpen("driver")}
                cardBuilder={company.drivers?.slice(0, 3).map((driver) => {
                  return (
                    <CardContainer
                      key={driver.id}
                      title={`${driver.firstName} ${driver.lastName}`}
                      subTitle={driver.phone}
                      onClick={() => navigate(`/chauffeur/detail/${driver.id}`, { state: { driverId: driver.id } })}
                    />
                  );
                })}
              />
              <DashboardSection
                title={content.labelVehicles}
                addCardLabel={content.addVehicles}
                emptyCardLabel={content.emptyVehicles}
                sectionInfo={company.races}
                seeMorRoute="/vehicule"
                addOnClick={() => handleOpen("vehicle")}
                cardBuilder={company.vehicles?.slice(0, 3).map((vehicle) => {
                  return (
                    <CardContainer
                      key={vehicle.id}
                      title={`${vehicle.brand} ${vehicle.model}`}
                      subTitle={vehicle.engine}
                      onClick={() => navigate(`/vehicule/detail/${vehicle.id}`, { state: { driverId: vehicle.id } })}
                    />
                  );
                })}
              />
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default Dashboard;
