import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { selectAuth } from "../../AuthProfile/services/authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { saveRace } from "./raceReducer";
import { saveRaces } from "./racesReducer";
import { Race } from "../utils/_interface";

const useRaceReq = () => {
  const dispatch = useDispatch();
  const [sendRequest] = useHttp();
  const { token, profileId } = useSelector(selectAuth);

  // Get a Race from the database
  const getRaces = useCallback((companyId: number) => {
    const config = requestConfigBuilder("GET", `race/profile=${profileId}/company=${companyId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { races } = response;
        dispatch(saveRaces(races));
      }
    });
  }, []);

  // Get a Races in driver from  the database
  const getDriverRaces = useCallback(() => {
    const config = requestConfigBuilder("GET", `race/profile=${profileId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { races } = response;
        dispatch(saveRaces(races));
      }
    });
  }, []);

  // Get a Race from  the database
  const getRace = useCallback((raceId: string) => {
    const config = requestConfigBuilder("GET", `race/profile=${profileId}/race=${raceId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        dispatch(saveRace(response.race));
      }
    });
  }, []);

  // add a race to the database
  const addRace = useCallback((companyId: number, data: Race) => {
    const config = requestConfigBuilder("POST", `race/profile=${profileId}/company=${companyId}/new`, token, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getRaces(companyId);
      }
    });
  }, []);

  // edit a race in the database
  const putRace = useCallback((companyId: number, raceId: string, data: Race) => {
    const config = requestConfigBuilder(
      "PUT",
      `race/profile=${profileId}/company=${companyId}/edit/race=${raceId}`,
      token,
      data,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getRaces(companyId);
      }
    });
  }, []);

  // archive a race in the database
  const archiveRace = useCallback((companyId: number, raceId: string) => {
    const config = requestConfigBuilder(
      "DELETE",
      `race/profile=${profileId}/company=${companyId}/delete/race=${raceId}`,
      token,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getRaces(companyId);
      }
    });
  }, []);

  return { getRace, getRaces, getDriverRaces, addRace, putRace, archiveRace };
};

export default useRaceReq;
