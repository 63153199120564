import React, { FC } from "react";
import { Box, InputAdornment, OutlinedInput, Toolbar, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { alpha, styled } from "@mui/material/styles";
import { colors } from "../../Shared/utils/constants";
import { TableToolbarProps } from "../utils/_interface";
import { useSelector } from "react-redux";
import { selectProfile } from "../../AuthProfile/services/profileReducer";
import useBreakpoints from "../../Shared/services/useBreakpoints";

const SearchFieldCustom = styled(OutlinedInput)(({ theme }) => ({
  marginTop: "0.5rem",
  height: 43,
  [theme.breakpoints.up("sm")]: {
    width: 210,
  },
  "&.Mui-focused": {
    boxShadow: `0 4px 16px 0 ${alpha(colors.blue, 0.4)}`,
  },
  "& fieldset": {
    border: "none",
  },
}));

const ToolbarRaceTable: FC<TableToolbarProps> = ({
  filterDate,
  filterDriver,
  filterCustomer,
  onFilterDate,
  onFilterDriver,
  onFilterCustomer,
  filterStreet,
  onFilterStreet,
}) => {
  const profile = useSelector(selectProfile);
  const { downSm } = useBreakpoints();

  const content = {
    subTitle: "Recherchez par: ",
    placeholderDate: "Date/heure...",
    placeholderDriver: "Chauffeur...",
    placeholderCustomer: "Client...",
    placeholderStreet: "Adresse...",
  };

  return (
    <Toolbar
      sx={{
        flexWrap: "wrap",
        position: "relative",
        ...(profile.account?.accountTypeId === 1 ? { height: "230px" } : { height: "180px" }),
        ...(downSm ? { flexDirection: "column" } : { flexDirection: "row" }),
      }}
    >
      <Typography sx={{ flex: "1 100%" }} variant="h6" component="div">
        {content.subTitle}
      </Typography>
      <Box display="flex" flexDirection="column" position="absolute" right={0} top={downSm ? "24px" : 0}>
        <SearchFieldCustom
          value={filterDate}
          onChange={onFilterDate}
          placeholder={content.placeholderDate}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        {profile.account?.accountTypeId === 1 && (
          <SearchFieldCustom
            value={filterDriver}
            onChange={onFilterDriver}
            placeholder={content.placeholderDriver}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        )}
        <SearchFieldCustom
          value={filterCustomer}
          onChange={onFilterCustomer}
          placeholder={content.placeholderCustomer}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <SearchFieldCustom
          value={filterStreet}
          onChange={onFilterStreet}
          placeholder={content.placeholderStreet}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </Box>
    </Toolbar>
  );
};

export default ToolbarRaceTable;
