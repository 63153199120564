/** @jsxImportSource @emotion/react */
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { Box, Button as MuiButton, TextField } from "@mui/material";
import Button from "../../Shared/atoms/Button";
import useSearchCompany from "../services/useSearchCompany";
import SearchIcon from "@mui/icons-material/Search";
import useCompanyReq from "../services/useCompanyReq";
import { css } from "@emotion/react";
import { fadeIn } from "../../Shared/utils/keyframesStyle";
import { colors } from "../../Shared/utils/constants";

const textFieldCustom = {
  "& .MuiOutlinedInput-root": {
    color: colors.black,
    "&:hover fieldset": {
      borderColor: colors.blue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.blue,
    },
  },
  label: {
    color: colors.black,
  },
};

const CompanyForm: FC = () => {
  const { searchCompany, siretFieldHandler, siretValue, companyValue } = useSearchCompany();
  const [addressValue, setAddressValue] = useState<string>("");
  const [companyNameValue, setCompanyNameValue] = useState<string>("");
  const [disableButton, setDisableButton] = useState<boolean>(!companyValue.address);
  const { postCompany } = useCompanyReq();

  useEffect(() => {
    if (companyValue.companyName) {
      setCompanyNameValue(companyValue.companyName);
    }
    if (!!companyValue.address) {
      setDisableButton((currentState) => !currentState);
      setAddressValue(companyValue.address);
    }
  }, [companyValue]);

  const companyNameHandler = (e: ChangeEvent<HTMLInputElement>) => setAddressValue(e.target.value);
  const addressHandler = (e: ChangeEvent<HTMLInputElement>) => setAddressValue(e.target.value);

  return (
    <Box
      css={css`
        animation: ${fadeIn} 300ms ease-in;
      `}
    >
      <Box sx={{ position: "relative", width: "100%" }}>
        <TextField
          sx={{ ...textFieldCustom, margin: ".5rem 0", width: "100%" }}
          name="siret"
          placeholder="Siret"
          onChange={siretFieldHandler}
          value={siretValue}
        />
        <Box sx={{ position: "absolute", right: 0, top: ".5rem", height: "3.5rem", display: "flex" }}>
          <MuiButton variant="contained" onClick={searchCompany.bind(this, siretValue)}>
            <SearchIcon />
          </MuiButton>
        </Box>
      </Box>
      <TextField
        sx={{ ...textFieldCustom, margin: ".5rem 0", width: "100%" }}
        name="companyName"
        placeholder="Nome de la compagnie"
        onChange={companyNameHandler}
        value={companyNameValue}
        disabled={!companyValue.siret}
      />
      <TextField
        sx={{ ...textFieldCustom, margin: ".5rem 0", width: "100%" }}
        name="address"
        placeholder="Adresse"
        onChange={addressHandler}
        value={addressValue}
        disabled={!companyValue.siret}
      />
      <Button
        margin="1rem 0"
        label="Sauvegarder la compagnie"
        disabled={disableButton}
        eventClick={() => postCompany(companyValue)}
      />
    </Box>
  );
};

export default CompanyForm;
