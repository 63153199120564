export const COURSE_TYPE = [
  { key: "medical", label: "Médical" },
  { key: "paying", label: "Payante" },
  { key: "school", label: "Scolaire" },
  { key: "contract", label: "Contrat" },
];

export const STATUS_TYPE = [
  { key: "unassigned", label: "Non affecté" },
  { key: "assigned", label: "Affecté" },
  { key: "accept", label: "Accepter" },
  { key: "unassigned", label: "Refusée" },
  { key: "approaching", label: "En approche" },
  { key: "waiting", label: "En attente" },
  { key: "started", label: "En cours" },
  { key: "completed", label: "Terminé" },
  { key: "cancelled", label: "Annulé" },
];
