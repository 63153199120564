import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Profile } from "../utils/_interface";

const profile: Profile = {} as Profile;

export const setProfile = createAction<Profile>("setProfile");

const profileReducer = createReducer(profile, (builder) =>
  builder.addCase(setProfile, (state, action: PayloadAction<Profile>) => {
    return action.payload;
  }),
);

export const selectProfile = (state: RootState) => state.profile;
export default profileReducer;
