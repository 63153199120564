/** @jsxImportSource @emotion/react */
import React, { FC, Fragment, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import makeStyles from "@mui/styles/makeStyles";
import { fadeIn } from "../../Shared/utils/keyframesStyle";
import { colors } from "../../Shared/utils/constants";

const content = {
  toCome: "À venir",
  onHold: "Terminer",
  archived: "Archivées",
};

const useStyles = makeStyles({
  tabs: {
    "&.MuiTabs-indicator": {
      display: "flex",
      backgroundColor: "red",
    },
    "&.MuiTabs-indicatorSpan": {
      width: "100%",
      backgroundColor: colors.blue,
    },
  },
  tab: {
    "&.MuiTab-root": {
      textTransform: "none",
      color: colors.black,
      "&.Mui-selected": {
        color: colors.blue,
      },
    },
  },
});

const RaceTabsTemplate: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const indexToTabName = () => {
    if (location.pathname === "/course/a-venir") {
      return 0;
    } else if (location.pathname === "/course/terminer") {
      return 1;
    } else if (location.pathname === "/course/archive") {
      return 2;
    }
  };

  const [tabValue, setTabValue] = useState(indexToTabName);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Fragment>
      <Box
        width="100%"
        minHeight="100%"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", sm: "flex-start" },
        }}
      >
        <Tabs
          variant="fullWidth"
          value={tabValue}
          onChange={handleChange}
          sx={{ marginBottom: "1.5rem", width: "100%" }}
          TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
        >
          <Tab
            disableRipple
            label={content.toCome}
            className={classes.tab}
            onClick={() => navigate("/course/a-venir")}
          />
          <Tab
            disableRipple
            label={content.onHold}
            className={classes.tab}
            onClick={() => navigate("/course/terminer")}
          />
          <Tab
            disableRipple
            label={content.archived}
            className={classes.tab}
            onClick={() => navigate("/course/archive")}
          />
        </Tabs>
        <Box
          component="div"
          width="100%"
          padding=".5rem"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            overflow: "initial",
          }}
          css={css`
            animation: ${fadeIn} 1s ease-in;
          `}
        >
          <Outlet />
        </Box>
      </Box>
    </Fragment>
  );
};

export default RaceTabsTemplate;
