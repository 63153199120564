import React, { FC, useState } from "react";
import { MapLibraries } from "../utils/types";
import { useJsApiLoader } from "@react-google-maps/api";
import { Typography } from "@mui/material";

interface LoadedMapWrapperProps {
  children: JSX.Element;
}

const LoadedMapWrapper: FC<LoadedMapWrapperProps> = ({ children }) => {
  const [libraries] = useState<MapLibraries>(["places"]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  if (!isLoaded) {
    return (
      <Typography variant="h3" component="h3">
        Chargement de la carte...
      </Typography>
    );
  }

  return <>{children}</>;
};

export default LoadedMapWrapper;
