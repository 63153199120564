import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { createTheme, CssBaseline, GlobalStyles, ThemeProvider } from "@mui/material";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import storeConfig, { storePersist } from "./Shared/services/storeConfig";
import { PersistGate } from "redux-persist/integration/react";
import { colors } from "./Shared/utils/constants";
import Router from "./Nav/Router";
import "@fontsource/dm-sans";

const globalStyles = (
  <GlobalStyles
    styles={{
      body: {
        fontSize: "16px",
        color: colors.black,
        letterSpacing: "0.5px",
        fontWeight: 400,
        backgroundColor: colors.white,
      },
      h1: {
        color: colors.navyBlue,
      },
    }}
  />
);

const theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "Roboto"].join(","),
  },
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <CssBaseline>
    {globalStyles}
    <Provider store={storeConfig}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={storePersist}>
          <SnackbarProvider
            maxSnack={1}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </SnackbarProvider>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </CssBaseline>,
);
