import React, { FC } from "react";
import { Box, SxProps, Typography, TypographyVariant } from "@mui/material";
import { TextAlign, TitleHeading } from "../utils/types";

export interface TitleProps {
  title: string | JSX.Element;
  component: TitleHeading;
  textAlign?: TextAlign;
  subTitle?: string;
  fontWeight?: string | number;
  fontSize?: string | number;
  variant?: TypographyVariant;
  sxTitle?: SxProps;
  sxSubTitle?: SxProps;
  icon?: React.ReactElement;
}

const Title: FC<TitleProps> = ({
  title,
  subTitle,
  textAlign,
  fontWeight,
  fontSize,
  component,
  variant,
  sxTitle,
  sxSubTitle,
  icon,
}) => {
  return (
    <Box textAlign={textAlign}>
      <Typography
        fontWeight={fontWeight}
        fontSize={fontSize}
        variant={variant}
        component={component}
        sx={{ ...sxTitle }}
      >
        {icon ? icon : null}
        <span>{title}</span>
      </Typography>
      {subTitle && (
        <Typography variant="caption" component="p" sx={{ ...sxSubTitle }}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};

Title.defaultProps = {
  textAlign: "center",
};

export default Title;
