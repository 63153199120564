import React, { FC, useEffect, useState } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { AccordionDetails, Box, Link, Paper, styled, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../Shared/atoms/Button";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import { saveRace, selectRace } from "../services/raceReducer";
import useRaceReq from "../services/useRaceReq";
import { selectCompany } from "../../Company/services/companyReducer";
import RaceForm from "../organisms/RaceForm";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";
import { getColorStatus, getLabelStatus, getLabelTypeRace, getStatusMessage } from "../utils/handleRace";
import { ManagementStatusRace } from "../organisms/ManagementStatusRace";
import { useSnackbar } from "notistack";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { ErrorProps } from "../../Shared/utils/_interface";
import { Race } from "../utils/_interface";
import { colors } from "../../Shared/utils/constants";

const SpanCustom = styled("span")(() => ({
  fontWeight: "bold",
  fontSize: "1rem",
}));

const RaceDetails: FC = () => {
  const navigate = useNavigate();
  const { raceId } = useParams();
  const dispatch = useDispatch();
  const race = useSelector(selectRace);
  const { type } = useSelector(selectError);
  const { downSm, downMd } = useBreakpoints();
  const { getRace } = useRaceReq();
  const [open, setOpen] = useState(false);
  const company = useSelector(selectCompany);
  const { enqueueSnackbar } = useSnackbar();
  useRedirectNotCompany();

  const handleErrorStatus = (message: string | undefined) => {
    switch (message) {
      case "forbiddenAccessStatus":
        return enqueueSnackbar(`Statut réservé aux administrateurs!`, { variant: "error" });
      case "sameStatus":
        return enqueueSnackbar(`Statut identique!`, { variant: "error" });
      case "ne pas sauter le statut":
        return enqueueSnackbar(`Veuillez ne pas sauter de statut`, { variant: "error" });
      case "onlyOnePossibleStatus":
        return enqueueSnackbar(`Veuillez changer un statut à la fois `, { variant: "error" });
      case "onlyCompletedStatus":
        return enqueueSnackbar(`Seul le statut complété et possible`, { variant: "error" });
      case "raceFinish":
        return enqueueSnackbar(`La course et terminer`, { variant: "error" });
      case "raceCancelled":
        return enqueueSnackbar(`La course à était annuler`, { variant: "error" });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    handleErrorStatus(type);
    return () => clearTimeout(timer);
  }, [type]);

  useEffect(() => {
    if (raceId != null) {
      getRace(raceId);
    } else {
      navigate("/course/a-venir", { replace: true });
    }
  }, [company.drivers]);

  const handleBack = () => {
    navigate(-1);
    const timer = setTimeout(() => {
      dispatch(saveRace({} as Race));
    }, 500);
    return () => clearTimeout(timer);
  };

  const content = {
    infoTitle: "Information: ",
    dataTimeRace: "Date et heure de la course: ",
    updateRace: "Modifier la course",
    startingAddress: "Adresse de départ: ",
    arrivalAddress: "Adresse d'arrivée: ",
    customer: "Client: ",
    validate: "Valider",
    back: "Retour",
    status: "Status actuel: ",
    statutes: "Les statuts",
    editStatus: "Choisir un statut",
  };

  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.updateRace}
        main={<RaceForm raceId={raceId} edit eventDialog={() => setOpen(false)} />}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage title={`course numero: # ${raceId}`} buttonText={content.back} buttonClick={handleBack} />
            {/* INFORMATION CHAUFFEUR */}
            <Paper elevation={3} sx={{ padding: "1rem", marginBottom: "1rem" }}>
              <Box display="flex" flexDirection={downSm ? "column" : "row"}>
                <Typography
                  textAlign={downSm ? "center" : "start"}
                  variant={downSm ? "h5" : "h3"}
                  component="h3"
                  marginY="1rem"
                >
                  {content.infoTitle}
                </Typography>
                <Box
                  display="flex"
                  width="100%"
                  flexDirection={downSm ? "column" : "row"}
                  justifyContent="space-between"
                >
                  <Button eventClick={() => setOpen(true)} label={<EditIcon fontSize="large" />} variant="text" />
                  <Typography
                    variant="body1"
                    fontWeight="bold"
                    bgcolor={getColorStatus(race.lastStatus)}
                    component="p"
                    borderRadius="5px"
                    padding="5px"
                    marginY="auto"
                  >
                    <SpanCustom>{content.status}</SpanCustom>
                    {getLabelStatus(race.lastStatus)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.dataTimeRace}</SpanCustom>
                {`${new Date(race.dateTimeRace!).toLocaleString()}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.startingAddress}</SpanCustom>
                {race.startingAddress}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.arrivalAddress}</SpanCustom>
                {race.arrivalAddress}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.customer}</SpanCustom>
                {race.customer?.firstName} {race.customer?.lastName}
              </Typography>
              <Link href={`tel:+${race.customer?.phone}`} sx={{ color: colors.black, textDecoration: "none" }}>
                <Typography variant="body2" component="p" marginY=".5rem">
                  <SpanCustom>{`Tel: ${race.customer?.phone}`}</SpanCustom>
                </Typography>
              </Link>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>type : </SpanCustom>
                {`${race.type ? getLabelTypeRace(race.type) : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Commentaire : </SpanCustom>
                {`${race.note ? race.note : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                {race.driver ? (
                  <>
                    <SpanCustom>Course attribuée" à : </SpanCustom>
                    {`${race.driver?.firstName} ${race.driver?.lastName}`}
                  </>
                ) : (
                  <SpanCustom>Course non attribuée. </SpanCustom>
                )}
              </Typography>
              {/* RACE-STATUS */}
              <Paper elevation={4} sx={{ ...(downSm ? { padding: 0 } : { padding: "1rem" }) }}>
                <Box display="flex" flexDirection={downSm ? "column" : "row"} alignItems="center" paddingY="10px">
                  <Typography fontWeight="bold" variant={downSm ? "body1" : "h5"} flexGrow={1}>
                    {content.statutes}
                  </Typography>
                  <ManagementStatusRace raceId={raceId!} />
                </Box>

                {Array.isArray(race.raceStatus) && race.raceStatus?.length ? (
                  race.raceStatus.map((statut, index) => {
                    const fullName = `${statut.firstName} ${statut.lastName}`;
                    const assigned = `${race.driver?.firstName} ${race.driver?.lastName}`;
                    return (
                      <AccordionDetails key={index} sx={{ bgcolor: getColorStatus(statut.type) }}>
                        <Typography variant="body2" component="p">
                          <SpanCustom>Description : </SpanCustom>
                          {getStatusMessage(statut.type, fullName, assigned, statut.accountTypeId)}
                        </Typography>
                        <Typography variant="body2" component="p">
                          <SpanCustom>Établis-le : </SpanCustom>
                          {`${statut.createdAt ? new Date(statut.createdAt!).toLocaleString() : "..."}`}
                        </Typography>
                      </AccordionDetails>
                    );
                  })
                ) : (
                  <></>
                )}
                {race.cancellation !== null && (
                  <Box display="flex" flexDirection={downSm ? "column" : "row"} alignItems="center" paddingY="10px">
                    <Typography fontWeight="bold" variant={downSm ? "body1" : "h5"}>
                      Motif de l'annulation :
                    </Typography>
                    <Typography variant="body1" component="p" margin="4px 0 0 4px">
                      {race.cancellation}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Paper>
          </Box>
        }
      />
    </>
  );
};

export default RaceDetails;
