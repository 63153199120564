import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { Circle, DirectionsRenderer, GoogleMap, Marker } from "@react-google-maps/api";
import { DirectionResult, LatLngLiteral, MapOption } from "../utils/types";
import { Box } from "@mui/material";
import { colors } from "../utils/constants";
import Distance from "../molecules/maps/Distance";
import { DrawerTemplate } from "../templates/DrawerTemplate";
import { AutocompleteField } from "../molecules/fields/AutocompleteMapsField";
import { useForm } from "react-hook-form";

export const Maps: FC = () => {
  const [starting, setStarting] = useState<LatLngLiteral>();
  const [directions, setDirections] = useState<DirectionResult>();
  const mapRef = useRef<GoogleMap>();
  const center = useMemo<LatLngLiteral>(() => ({ lat: 48.5672962, lng: 7.728768 }), []);
  const options = useMemo<MapOption>(
    () => ({
      disableDefaultUi: true,
      clickableIcons: false,
      streetViewControl: false,
      mapTypeControl: false,
    }),
    [],
  );
  const onLoad = useCallback((map) => (mapRef.current = map), []);

  const generatesDrivers = (position: LatLngLiteral) => {
    const drivers: Array<LatLngLiteral> = [];
    for (let i = 0; i < 15; i++) {
      const direction = Math.random() < 0.5 ? -7 : 7;
      drivers.push({
        lat: position.lat + Math.random() / direction,
        lng: position.lng + Math.random() / direction,
      });
    }
    return drivers;
  };

  const drivers = useMemo(() => generatesDrivers(center), [center]);

  const fetchDirections = (house: LatLngLiteral) => {
    if (!starting) return;
    const service = new google.maps.DirectionsService();
    service.route(
      {
        origin: house,
        destination: starting,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === "OK" && result) {
          setDirections(result);
        }
      },
    );
  };

  const {
    control,
    formState: { errors },
  } = useForm();

  const circleOptions = {
    strokeOpacity: 0.5,
    fillOpacity: 0.1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    zIndex: 3,
    strokeColor: colors.success,
    fillColor: colors.success,
  };

  return (
    <DrawerTemplate
      main={
        <Box display="flex" flexDirection="column" height="90vh" width="100%">
          <Box width="100%" height="20%">
            <AutocompleteField
              name="newClient"
              error={errors}
              control={control}
              setStarting={(position) => {
                setStarting(position);
                mapRef.current?.panTo(position);
              }}
            />

            {!starting && <p>Saisissez l'adresse de départ</p>}
            {starting && !directions && <p>Saisissez un chauffeur</p>}
            {directions && <Distance leg={directions.routes[0].legs[0]} />}
          </Box>
          <GoogleMap
            center={center}
            zoom={13}
            mapContainerStyle={{ width: "100%", height: "100%" }}
            options={options}
            onLoad={onLoad}
          >
            {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  polylineOptions: {
                    zIndex: 10,
                    strokeColor: colors.blue,
                    strokeWeight: 5,
                  },
                }}
              />
            )}

            {starting && (
              <>
                <Marker position={starting} icon="https://zupimages.net/up/22/26/b5lg.png" />
                {drivers.map((driver) => (
                  <Marker
                    key={driver.lat + driver.lng}
                    position={driver}
                    onClick={() => {
                      fetchDirections(driver);
                    }}
                    icon="https://zupimages.net/up/20/45/juk8.png"
                  />
                ))}
                <Circle center={starting} radius={5000} options={circleOptions} />
              </>
            )}
          </GoogleMap>
        </Box>
      }
    />
  );
};
