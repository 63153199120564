import React, { FC } from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Order } from "../../Shared/utils/types";
import { HeadCellsProps } from "../utils/headCellRace";
import { DataRace } from "../utils/_interface";

interface TableHeaderProps {
  headCells: readonly HeadCellsProps[];
  order: Order;
  orderBy: string;
  sortingOnRequest: (event: React.MouseEvent<unknown>, property: keyof DataRace) => void;
  accountType?: number;
}

const HeadRaceTable: FC<TableHeaderProps> = ({ sortingOnRequest, order, orderBy, headCells, accountType }) => {
  const createSortHandler = (property: keyof DataRace) => (event: React.MouseEvent<unknown>) => {
    sortingOnRequest(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {accountType === 1 && (
          <TableCell align="center" padding="none" sortDirection={orderBy === "driver" ? order : false}>
            <TableSortLabel
              active={orderBy === "driver"}
              direction={orderBy === "driver" ? order : "asc"}
              onClick={createSortHandler("driver")}
            >
              {"Chauffeur"}
              {orderBy === "driver" ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default HeadRaceTable;
