export const TYPE_VEHICLE = [
  { key: "Suv", label: "Suv" },
  { key: "Berline", label: "Berline" },
  { key: "Citadine", label: "Citadine" },
  { key: "Minibus", label: "Minibus" },
  { key: "Monospace", label: "Monospace" },
];

export const ENGINE_VEHICLE = [
  { key: "Essence", label: "Essence" },
  { key: "Diesel", label: "Diesel" },
  { key: "Hybride", label: "Hybride" },
  { key: "Électrique", label: "Électrique" },
];

export const NUMBER_OF_SEATS = [
  { key: "3", label: "3" },
  { key: "4", label: "4" },
  { key: "5", label: "5" },
  { key: "6", label: "6" },
];

export const COLORS_VEHICLE = [
  { key: "Beige", label: "Beige" },
  { key: "Blanc", label: "Blanc" },
  { key: "Bleu", label: "Bleu" },
  { key: "Gris", label: "Gris" },
  { key: "Marron", label: "Marron" },
  { key: "Noir", label: "Noir" },
  { key: "Vert", label: "Vert" },
];
