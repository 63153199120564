import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Profile } from "../../AuthProfile/utils/_interface";

const customer: Profile = {} as Profile;

export const setCustomer = createAction<Profile>("setCustomer");

const customerReducer = createReducer(customer, (builder) =>
  builder.addCase(setCustomer, (state, action: PayloadAction<Profile>) => {
    return action.payload;
  }),
);

export const selectCustomer = (state: RootState) => state.customer;
export default customerReducer;
