import { ChangeEvent, useCallback, useState } from "react";
import useHttp from "../../Shared/services/useHttp";
import { RequestConfigInterface } from "../../Shared/utils/_interface";
import { Company } from "../utils/_interface";

const useSearchCompany = () => {
  const [sendRequest] = useHttp();
  const [siretValue, setSiretValue] = useState<string>("");
  const [companyValue, setCompanyValueValue] = useState<Company>({} as Company);

  const siretFieldHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSiretValue(e.target.value);
  };

  const searchCompany = useCallback((siretParam: string) => {
    const config: RequestConfigInterface = {
      method: "GET",
      url: `${process.env.REACT_APP_INSEE_API}/${siretParam}`,
      headers: { "Content-Type": "application/json", Authorization: `Bearer ${process.env.REACT_APP_TOKEN_INSEE}` },
    };

    sendRequest(config)
      .then((data: any) => {
        const {
          etablissement: {
            siret,
            adresseEtablissement: {
              codePostalEtablissement,
              libelleCommuneEtablissement,
              libelleVoieEtablissement,
              numeroVoieEtablissement,
              typeVoieEtablissement,
            },
            uniteLegale: { denominationUniteLegale },
          },
        } = data;

        const company: Company = {
          companyName: denominationUniteLegale,
          siret,
          address: `${numeroVoieEtablissement} ${typeVoieEtablissement} ${libelleVoieEtablissement} ${codePostalEtablissement} ${libelleCommuneEtablissement}`,
        };

        setCompanyValueValue(company);
      })
      .catch((error) => console.log("error searchCompany =>", error));
  }, []);

  return { searchCompany, siretFieldHandler, siretValue, companyValue };
};

export default useSearchCompany;
