import { object, ref, string } from "yup";

export const errorsMessage = {
  requiredMsg: "Veuillez remplir ce champ",
  requiredEmail: "Veuillez renseigner votre courriel",
  invalidEmail: "Veuillez respecter le format courriel",
  requiredPassword: "Veuillez renseigner un mot de passe",
  minPassword: "Le champ doit avoir minimum 6 caractères",
  confirm: "Les mots de passe doivent correspondre",
  lengthPhone: "Le numéro de téléphone doit contenir 10 chiffres.",
  lengthPostcode: "Le code postal doit contenir 5 chiffres.",
  positiveRadius: "le rayon doit être un nombre positif.",
  typeErrorRadius: "Rayon d'action doit avoir un nombre.",
  typeErrorNbSeats: "le nombre de sièges doit être un nombre.",
  "unique violation": "L'adresse email est déjà utilisée",
  "login failed": "Identifiant incorrect",
  notAlpha: "Les chiffres ne sont pas autorisées",
};

const emailField = {
  email: string().email(errorsMessage.invalidEmail).required(errorsMessage.requiredEmail).trim(),
};

const passwordField = {
  password: string().min(6, errorsMessage.minPassword).required(errorsMessage.requiredPassword).trim(),
};

const profileFields = {
  firstName: string().required(errorsMessage.requiredMsg).trim(),
  lastName: string().required(errorsMessage.requiredMsg).trim(),
  phone: string().max(10, errorsMessage.minPassword).required(errorsMessage.requiredMsg).trim(),
  note: string().nullable().trim(),
};

const confirmPasswordField = {
  confirmPassword: string()
    .required(errorsMessage.requiredMsg)
    .oneOf([ref("password"), null], errorsMessage.confirm)
    .trim(),
};
export const signInSchema = object().shape({ ...emailField, ...passwordField });
export const forgoPasswordSchema = object().shape({ ...emailField });
export const resetPasswordSchema = object().shape({ ...passwordField, ...confirmPasswordField });
export const signUpSchema = object().shape({ ...emailField, ...passwordField, ...confirmPasswordField });
export const profileSchema = object().shape({ ...emailField, ...profileFields });
export const customerSchema = object().shape({ ...profileFields });
