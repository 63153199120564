import { filter } from "lodash";
import { Order } from "../../Shared/utils/types";

const useFilterRace = () => {
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function applySortFilter(array, comparator, queryDate, queryName, queryCustomer, queryStreet) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (queryDate) {
      return filter(array, (_data) => _data.dateTimeRace.toLowerCase().indexOf(queryDate.toLowerCase()) !== -1);
    }
    if (queryName) {
      return filter(array, (_data) => _data.driver.toLowerCase().indexOf(queryName.toLowerCase()) !== -1);
    }
    if (queryCustomer) {
      return filter(array, (_data) => _data.customer.toLowerCase().indexOf(queryCustomer.toLowerCase()) !== -1);
    }
    if (queryStreet) {
      return filter(array, (_data) => _data.startingAddress.toLowerCase().indexOf(queryStreet.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  return { applySortFilter, getComparator };
};

export default useFilterRace;
