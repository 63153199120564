import { useCallback } from "react";
import { login, selectAuth } from "./authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { setIsSignUp } from "./signUpReducer";
import { setProfile } from "./profileReducer";
import { initProfile } from "../../Shared/services/initialStore";
import { useNavigate } from "react-router-dom";
import { setAccount } from "./accountReducer";

const useAuthReq = (url?: string) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);
  const [sendRequest] = useHttp();

  const postAuthRequest = useCallback((data: { email: string; password: string; confirmPassword?: string }) => {
    const config = requestConfigBuilder("POST", url!, undefined, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        if (response.signup) {
          dispatch(setIsSignUp({ isSignup: response.signup }));
        }
        dispatch(login(response));
      }
    });
  }, []);

  const getAccountRequest = useCallback((email: string) => {
    const config = requestConfigBuilder("GET", `auth/account/email=${email}`);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        dispatch(setAccount(response.account));
      }
    });
  }, []);

  const deleteLogoutRequest = useCallback(() => {
    const config = requestConfigBuilder("DELETE", "auth/logout", token);
    sendRequest(config).then(() => {
      dispatch(login({ accountTypeId: "", profileId: "", token: "" }));
      dispatch(setProfile(initProfile));
    });
  }, []);

  const putForgotPasswordRequest = useCallback((data: { email: string }) => {
    const config = requestConfigBuilder("PUT", url!, undefined, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        navigate("/connexion");
      }
    });
  }, []);

  const putResetPasswordRequest = useCallback(
    (data: { password: string; confirmPassword?: string; restLink: string }) => {
      const config = requestConfigBuilder("PUT", "auth/reset-password", undefined, data);
      sendRequest(config).then((response) => {
        if (response.status === "error") {
          dispatch(setError(response));
        } else if (response.status === "success") {
          console.log("succès réinitialisation du mot de passe");
        }
      });
    },
    [],
  );

  return { postAuthRequest, getAccountRequest, deleteLogoutRequest, putForgotPasswordRequest, putResetPasswordRequest };
};

export default useAuthReq;
