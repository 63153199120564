import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./storeConfig";
import { ErrorProps } from "../utils/_interface";

const error: ErrorProps = {} as ErrorProps;

export const setError = createAction<ErrorProps>("setError");

const errorReducer = createReducer(error, (builder) =>
  builder.addCase(setError, (state, action: PayloadAction<ErrorProps>) => {
    return action.payload;
  }),
);

export const selectError = (state: RootState) => state.error;
export default errorReducer;
