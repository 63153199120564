import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../Shared/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSignUp, setIsSignUp } from "../services/signUpReducer";
import { AuthTemplate } from "../templates/AuthTemplate";

const SignUp = () => {
  const { isSignup } = useSelector(selectIsSignUp);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const redirectHandler = () => {
    dispatch(setIsSignUp({ isSignup: false }));
  };

  useEffect(() => {
    if (!isSignup) {
      navigate("/");
    }
  }, [isSignup]);

  const content = {
    title: "FÉLICITATIONS !",
    subTitle: "Votre inscription a bien été prise en compte. Vous pouvez dorénavant accéder à votre Tableau de bord.",
    message: "Pour utiliser toutes les fonctionnalités, il vous sera demander de compléter votre profil.",
    button: "C'EST PARTI !",
  };

  return (
    <AuthTemplate
      isSuccessSignUp
      title={content.title}
      main={
        <Typography textAlign="center" variant="body1" marginX="1rem">
          {content.subTitle}
        </Typography>
      }
      footerHeight="20vh"
      footer={
        <>
          <Typography textAlign="center" variant="subtitle2">
            {content.message}
          </Typography>
          <Button label={content.button} eventClick={redirectHandler} />
        </>
      }
    />
  );
};

export default SignUp;
