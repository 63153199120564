import React, { FC, useEffect, useState } from "react";
import DropdownMenuCompany from "../molecules/DropdownMenuCompany";
import { Box, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ListItemLink } from "../molecules/ListItemLink";
import DashboardIcon from "@mui/icons-material/Dashboard";
import RouteIcon from "@mui/icons-material/Route";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi";
import HailIcon from "@mui/icons-material/Hail";
import Button from "../atoms/Button";
import Title from "../atoms/Title";
import { useSelector } from "react-redux";
import { selectProfile } from "../../AuthProfile/services/profileReducer";
import { selectCompany } from "../../Company/services/companyReducer";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../utils/constants";
import MapIcon from "@mui/icons-material/Map";

const useStyles = makeStyles({
  span: {
    fontSize: "20px",
    color: colors.gray,
  },
});

interface ListDrawerProps {
  drawerWidth?: number;
}

export const ListDrawer: FC<ListDrawerProps> = ({ drawerWidth }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const profile = useSelector(selectProfile);
  const company = useSelector(selectCompany);
  const [companiesArray, setCompaniesArray] = useState([]);

  useEffect(() => {
    if (Array.isArray(profile.companies) && profile.companies.length) {
      const arrayCache: any = [];
      profile.companies.forEach((value) => {
        arrayCache.push({ key: `${value.id}`, label: `${value.companyName}` });
      });
      setCompaniesArray(arrayCache);
    }
  }, [profile.companies]);

  const content = {
    dashboard: "Tableau de bord",
    company: "Ma compagnie",
    notCompany: "Pas de compagnie",
    races: "Les courses",
    customers: "Mes clients",
    map: "Carte",
    drivers: "Mes chauffeurs",
    vehicles: "Mes véhicules",
    message: "Pour accéder à toutes les fonctionnalités de Becose",
    buttonToCompleted: "Compléter le profil",
    buttonCompleted: "Profil complété",
  };

  return (
    <Box sx={{ height: "100%", width: drawerWidth }}>
      <DropdownMenuCompany title={content.company} options={companiesArray} />
      <ListItem>
        <ListItemText
          primary={
            <Title
              title={
                company.companyName ? company.companyName : <span className={classes.span}>{content.notCompany}</span>
              }
              textAlign="left"
              fontWeight="bold"
              variant="h5"
              component="h1"
            />
          }
        />
      </ListItem>
      <Divider />
      <List component="nav">
        <ListItemLink to="/" title={content.dashboard} icon={<DashboardIcon />} />
        <ListItemLink to="/course/a-venir" title={content.races} icon={<RouteIcon />} />
        <ListItemLink to="/chauffeur" title={content.drivers} icon={<PeopleAltIcon />} />
        <ListItemLink to="/vehicule" title={content.vehicles} icon={<LocalTaxiIcon />} />
        <ListItemLink to="/client" title={content.customers} icon={<HailIcon />} />
        <ListItemLink to="/carte" title={content.map} icon={<MapIcon />} />
      </List>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="absolute"
        bottom={0}
        marginBottom="3rem"
      >
        {profile.firstName ? (
          <Box marginLeft="1.8rem">
            <Button label={content.buttonCompleted} type="dark" />
          </Box>
        ) : (
          <>
            <Typography variant="button" textAlign="center">
              {content.message}
            </Typography>
            <Button label={content.buttonToCompleted} eventClick={() => navigate("/profil")} />
          </>
        )}
      </Box>
    </Box>
  );
};
