import { Profile } from "../../AuthProfile/utils/_interface";

export const initProfile: Profile = {
  id: 0,
  firstName: "",
  lastName: "",
  phone: "",
  note: "",
  companies: [],
  vehicle: {
    type: "",
    brand: "",
    color: "",
    engine: "",
    licencePlate: "",
    model: "",
    nbSeat: "",
    taxiLicence: "",
  },
  account: {
    id: 0,
    email: "",
    password: "",
  },
  address: "",
};
