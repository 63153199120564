import React, { FC, useEffect, useState } from "react";
import useProfileReq from "../services/useProfileReq";
import Title from "../../Shared/atoms/Title";
import AccordionTemplate from "../templates/AccordionTemplate";
import { useSelector } from "react-redux";
import { selectProfile } from "../services/profileReducer";
import { Box, lighten, Typography } from "@mui/material";
import Button from "../../Shared/atoms/Button";
import CompanyForm from "../../Company/organisms/CompanyForm";
import ProfileForm from "../organisms/ProfileForm";
import { colors } from "../../Shared/utils/constants";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { DrawerDriverTemplate } from "../../Shared/templates/DrawerDriverTemplate";

const Profile: FC = () => {
  const profile = useSelector(selectProfile);
  const { getProfileReq } = useProfileReq();
  const [showFormCompany, setShowFormCompany] = useState<boolean>(false);

  useEffect(() => {
    getProfileReq();
  }, [showFormCompany]);

  const content = {
    title: "MON PROFIL",
    lastName: `${profile.firstName} ${profile.lastName}`,
    email: `${profile.account?.email}`,
    persoAccordion: "Informations personnelles",
    adminAccordion: "Informations administratives",
    addCompany: "+ AJOUTER UNE COMPAGNIE",
    cancelCompany: "ANNULER L'AJOUT",
    indexCompany: ">Compagnie #",
  };

  return profile.account?.accountTypeId === 1 ? (
    <DrawerTemplate
      main={
        <Box display="flex" flexDirection="column" width="100%" padding="20px">
          {/* TITLE */}
          <Title component="h1" title={content.title} fontWeight={700} fontSize="20px" />
          <Title component="h2" title={content.lastName} fontWeight={500} fontSize="20px" />
          <Typography fontSize="14px" color={lighten(colors.black, 0.6)}>
            {content.email}
          </Typography>
          {/* FORMS PERSO */}
          <AccordionTemplate title={content.persoAccordion}>
            <ProfileForm edit={!!profile.firstName} />
          </AccordionTemplate>
          {/* FORMS COMPANY */}
          {profile.account?.accountTypeId === 1 && (
            <AccordionTemplate title={content.adminAccordion} eventClick={() => setShowFormCompany(false)}>
              <Box>
                <Button
                  margin="0 0 1rem 0"
                  label={showFormCompany ? content.cancelCompany : content.addCompany}
                  type="info"
                  eventClick={() => setShowFormCompany(!showFormCompany)}
                />
                {showFormCompany && <CompanyForm />}
                {profile.companies?.map((company, index) => (
                  <Box alignItems="center" display="flex" width="100%" key={company.id}>
                    <Typography width="150px" color={colors.gray}>
                      {`${content.indexCompany} ${index + 1}`}
                    </Typography>
                    <Typography>{company.companyName}</Typography>
                  </Box>
                ))}
              </Box>
            </AccordionTemplate>
          )}
        </Box>
      }
    />
  ) : (
    <DrawerDriverTemplate
      main={
        <Box display="flex" flexDirection="column" width="100%" padding="20px">
          {/* TITLE */}
          <Title component="h1" title={content.title} fontWeight={700} fontSize="20px" />
          <Title component="h2" title={content.lastName} fontWeight={500} fontSize="20px" />
          <Typography fontSize="14px" color={lighten(colors.black, 0.6)}>
            {content.email}
          </Typography>
          {/* FORMS PERSO */}
          <AccordionTemplate title={content.persoAccordion}>
            <ProfileForm edit={!!profile.firstName} />
          </AccordionTemplate>
        </Box>
      }
    />
  );
};

export default Profile;
