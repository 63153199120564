import React, { FC } from "react";
import { Box, ListItemButton, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../utils/constants";

const useStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: colors.black,
  },
  active: {
    textDecoration: "none",
    fontWeight: "900",
    "& div": {
      color: colors.blue,
    },
  },
});

interface ListItemLinkProps {
  icon?: React.ReactElement;
  title: string;
  to: string;
}

export const ListItemLink: FC<ListItemLinkProps> = ({ icon, title, to }) => {
  const classes = useStyles();

  return (
    <ListItemButton>
      <NavLink to={to} className={(navData) => (navData.isActive ? classes.active : classes.link)}>
        <Box display="flex">
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={title} />
        </Box>
      </NavLink>
    </ListItemButton>
  );
};
