import React, { FC } from "react";
import { Dialog as MuiDialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Button from "../atoms/Button";

interface DialogProps {
  openDialog: boolean;
  handleDialog: () => void;
  handleEvent?: () => void;
  dialogTitle: string;
  dialogContentText: string;
  DialogFirstButtonTitle?: string;
  DialogSecondButtonTitle?: string;
}

const Dialog: FC<DialogProps> = ({
  openDialog,
  dialogTitle,
  handleDialog,
  handleEvent,
  dialogContentText,
  DialogFirstButtonTitle,
  DialogSecondButtonTitle,
}) => {
  return (
    <MuiDialog open={openDialog} onClose={handleDialog}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText>{dialogContentText}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button label={DialogSecondButtonTitle} variant="text" eventClick={handleDialog} />
        <Button label={DialogFirstButtonTitle} variant="text" type="error" eventClick={handleEvent} />
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
