import { useCallback } from "react";
import axios from "axios";
import { RequestConfigInterface } from "../utils/_interface";

const useHttp = () => {
  const sendRequest = useCallback(async (requestConfig: RequestConfigInterface) => {
    const { method, url, headers, body } = requestConfig;
    try {
      const res = await axios({
        method,
        url,
        headers,
        data: body,
      });
      return res.data;
    } catch (error: any) {
      return {
        type: error.response.data.errors[0].type,
        message: error.response.data.errors[0].message,
        status: "error",
      };
    }
  }, []);

  return [sendRequest];
};

export default useHttp;
