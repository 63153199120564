import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCompany } from "../../Company/services/companyReducer";
import { useSnackbar } from "notistack";

const useRedirectNotCompany = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const company = useSelector(selectCompany);

  useEffect(() => {
    if (!company.id) {
      navigate("/", { replace: true });
      enqueueSnackbar(`Veuillez sélectionner une société`, { variant: "info" });
    }
  }, []);
};

export default useRedirectNotCompany;
