import React, { FC, useEffect, useState } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { Box, Grow, Paper, styled, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../Shared/atoms/Button";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import { selectDriver } from "../services/driverReducer";
import useDriverReq from "../services/useDriverReq";
import { selectCompany } from "../../Company/services/companyReducer";
import DriverForm from "../organisms/DriverForm";
import { SelectField } from "../../Shared/molecules/fields/SelectField";
import { useForm } from "react-hook-form";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import { ToggleSwitch } from "../../Shared/molecules/ToggleSwitch";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";

const SpanCustom = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const DriverDetails: FC = () => {
  const navigate = useNavigate();
  const { driverId } = useParams();
  const driver = useSelector(selectDriver);
  const { downSm, downMd } = useBreakpoints();
  const { getDriver, putDriver } = useDriverReq();
  const [vehicleChange, setVehicleChange] = useState(false);
  const [vehicleCompanyArray, setVehicleCompanyArray] = useState([]);
  const [open, setOpen] = useState(false);
  const company = useSelector(selectCompany);
  useRedirectNotCompany();

  useEffect(() => {
    if (driverId != null) {
      if (company.id != null) {
        getDriver(company.id, driverId);
      }
    } else {
      navigate("/chauffeur", { replace: true });
    }
  }, [company.drivers]);

  useEffect(() => {
    if (Array.isArray(company.vehicles) && company.vehicles.length) {
      const arrayCache: any = [];
      company.vehicles.forEach((value) => {
        arrayCache.push({ key: `${value.id}`, label: `${value.model} (${value.licencePlate})` });
      });
      setVehicleCompanyArray(arrayCache);
    }
  }, [open]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  const handleChangeVehicle = (data) => {
    const body = { ...data };
    if (driverId != null) {
      if (company.id != null) {
        putDriver(company.id, driverId, body);
      }
    }
  };

  const content = {
    infoTitle: "Information:",
    vehicleTitle: "Véhicule affecté:",
    updateDriver: "Modifier le chauffeur",
    toggleText: "Modifier le véhicule",
    validate: "Valider",
    back: "Retour",
  };

  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.updateDriver}
        main={<DriverForm driverId={driverId} edit eventDialog={() => setOpen(false)} />}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage
              title={`${driver.firstName} ${driver.lastName}`}
              buttonText={content.back}
              buttonClick={() => navigate(-1)}
            />
            {/* INFORMATION CHAUFFEUR */}
            <Paper elevation={3} sx={{ padding: "1rem", marginBottom: "1rem" }}>
              <Box display="flex" flexDirection={downSm ? "column" : "row"}>
                <Typography
                  textAlign={downSm ? "center" : "start"}
                  variant={downSm ? "h5" : "h3"}
                  component="h3"
                  marginY="1rem"
                >
                  {content.infoTitle}
                </Typography>
                <Button eventClick={() => setOpen(true)} label={<EditIcon />} variant="text" />
              </Box>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Adresse : </SpanCustom>
                {`${driver.address ? driver.address : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Téléphone : </SpanCustom>
                {driver.phone ? driver.phone : "..."}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Information : </SpanCustom>
                {`${driver.note ? driver.note : "..."}`}
              </Typography>
            </Paper>
            {/* VÉHICULE AFFECTÉ */}
            <Paper elevation={3} sx={{ padding: "1rem", marginBottom: "1rem" }}>
              <Box display="flex" flexDirection={downSm ? "column" : "row"} alignItems={downSm ? "center" : "start"}>
                <Typography
                  textAlign={downSm ? "center" : "start"}
                  variant={downSm ? "h5" : "h3"}
                  component="h3"
                  marginY="1rem"
                  width={downSm ? "100%" : "50%"}
                >
                  {content.vehicleTitle}
                </Typography>
                <Box display="flex" flexDirection="column" marginY="1rem" width={downSm ? "100%" : "50%"}>
                  <ToggleSwitch
                    label={content.toggleText}
                    checked={vehicleChange}
                    onChange={() => setVehicleChange((prev) => !prev)}
                  />
                  <Grow
                    in={vehicleChange}
                    style={{ transformOrigin: "0 0 0", display: vehicleChange ? "block" : "none" }}
                    {...(vehicleChange ? { timeout: 1000 } : {})}
                  >
                    <Box display="flex">
                      <SelectField
                        name="vehicleId"
                        options={vehicleCompanyArray}
                        error={errors}
                        control={control}
                        label="Modèle"
                      />
                      <Button eventClick={handleSubmit(handleChangeVehicle)} label={content.validate} variant="text" />
                    </Box>
                  </Grow>
                </Box>
              </Box>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Plaque d'immatriculation : </SpanCustom>
                {`${driver.vehicle?.licencePlate ? driver.vehicle?.licencePlate : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Model : </SpanCustom>
                {`${driver.vehicle?.brand ? driver.vehicle?.brand : "..."} 
                  ${driver.vehicle?.model ? driver.vehicle?.model : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Moteur : </SpanCustom>
                {driver.vehicle?.engine ? driver.vehicle.engine : "..."}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Nombre de sièges : </SpanCustom>
                {driver.vehicle?.nbSeat ? driver.vehicle.nbSeat : "..."}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Couleur : </SpanCustom>
                {driver.vehicle?.color ? driver.vehicle.color : "..."}
              </Typography>
            </Paper>
          </Box>
        }
      />
    </>
  );
};

export default DriverDetails;
