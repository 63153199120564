import { Method } from "axios";
import { RequestConfigInterface } from "./_interface";

export const requestConfigBuilder = (method: Method, route: string, token?: string, data?: any) => {
  const headers: {
    "Content-Type": string;
    Authorization?: string;
  } = { "Content-Type": "application/json" };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config: RequestConfigInterface = {
    method,
    url: `${process.env.REACT_APP_DOMAIN}/${route}`,
    headers,
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  return config;
};
