import React, { Fragment } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { selectAuth } from "../AuthProfile/services/authReducer";
import { useSelector } from "react-redux";

const PrivateRoute = () => {
  const { token } = useSelector(selectAuth);
  return <Fragment>{token ? <Outlet /> : <Navigate to="/connexion" />}</Fragment>;
};

export default PrivateRoute;
