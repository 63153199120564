import React, { ChangeEvent, FC } from "react";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export interface ToggleSwitchProps {
  label: string;
  name?: string;
  checked?: boolean;
  onChange?: (arg: ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleSwitch: FC<ToggleSwitchProps> = ({ label, checked, onChange, name }) => {
  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel control={<Switch checked={checked} onChange={onChange} name={name} />} label={label} />
      </FormGroup>
    </FormControl>
  );
};
