import React, { FC } from "react";
import { fr } from "date-fns/esm/locale";
import * as hookForm from "react-hook-form";
import { FieldErrors } from "react-hook-form";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { TextField } from "@mui/material";
import { colors } from "../../utils/constants";

const textFieldCustom = {
  "& .MuiOutlinedInput-root": {
    color: colors.black,
    "&:hover fieldset": {
      borderColor: colors.blue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.blue,
    },
  },
  label: {
    color: colors.black,
  },
};

interface DateFieldProps {
  control: hookForm.Control<any> | undefined;
  error: FieldErrors;
  name: string;
  label: string;
  defaultValue?: string | Date | null;
  disabled?: boolean;
  helperText?: string;
  margin?: string;
}

export const DateField: FC<DateFieldProps> = ({
  name,
  label,
  margin,
  error,
  helperText,
  control,
  defaultValue,
  disabled,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
      <hookForm.Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref } }) => (
          <DateTimePicker
            ref={ref}
            okText="Valider"
            cancelText="Annuler"
            label={label}
            value={value}
            disabled={disabled}
            onChange={(newValue) => onChange(newValue)}
            renderInput={(params) => (
              <TextField
                sx={{ ...textFieldCustom, margin: margin, width: "100%" }}
                {...params}
                error={!!error[name]}
                helperText={(error[name] && error[name]?.message) || helperText}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};

DateField.defaultProps = {
  margin: ".5rem 0",
};
