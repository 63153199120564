import React, { FC, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, lighten, Link, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { colors } from "../../Shared/utils/constants";
import Button from "../../Shared/atoms/Button";
import PageviewIcon from "@mui/icons-material/Pageview";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import { useNavigate } from "react-router-dom";
import { selectCompany } from "../../Company/services/companyReducer";

const SpanCustom = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const DriversAccordionInfo: FC = () => {
  const navigate = useNavigate();
  const { downSm } = useBreakpoints();
  const { drivers } = useSelector(selectCompany);
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box marginY="1rem">
      {Array.isArray(drivers) && drivers.length ? (
        drivers.map((driver, index) => {
          return (
            <Accordion key={index} expanded={expanded === driver.id} onChange={handleChange(driver.id!)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={driver.id?.toString()}>
                <Typography fontWeight="bold" width="33%" variant={downSm ? "body1" : "h5"} sx={{ flexShrink: 0 }}>
                  {`${driver.firstName} ${driver.lastName}`}
                </Typography>
                <Link href={`tel:+${driver.phone}`} sx={{ flexGrow: 1, textDecoration: "none" }}>
                  <Typography variant={downSm ? "body2" : "h6"} flexGrow={1} color={lighten(colors.black, 0.5)}>
                    {driver.phone ? driver.phone : "..."}
                  </Typography>
                </Link>
                <Button
                  eventClick={() => navigate(`/chauffeur/detail/${driver.id}`, { state: { driverId: driver.id } })}
                  label={<PageviewIcon />}
                  variant="text"
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" component="p">
                  <SpanCustom>Adresse : </SpanCustom>
                  {`${driver.address ? driver.address : "..."}`}
                </Typography>
                <Typography variant="body2" component="p">
                  <SpanCustom>Téléphone : </SpanCustom>
                  {driver.phone ? driver.phone : "..."}
                </Typography>
                <Typography variant="body2" component="p">
                  <SpanCustom>Information : </SpanCustom>
                  {`${driver.note ? driver.note : "..."}`}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <></>
      )}
    </Box>
  );
};
