import React, { FC, useState } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { Box } from "@mui/material";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import { VehiclesAccordionInfo } from "../organisms/VehicleAccordionInfo";
import VehicleForm from "../organisms/VehicleForm";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";
import useBreakpoints from "../../Shared/services/useBreakpoints";

const Vehicles: FC = () => {
  const [open, setOpen] = useState(false);
  const { downMd } = useBreakpoints();
  useRedirectNotCompany();

  const content = {
    title: "Mes véhicule",
    addVehicle: "Ajouter un véhicule",
  };
  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.addVehicle}
        main={<VehicleForm eventDialog={() => setOpen(false)} />}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage title={content.title} buttonText={content.addVehicle} buttonClick={() => setOpen(true)} />
            <Box display="flex" flexDirection="column" width="100%">
              <VehiclesAccordionInfo />
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default Vehicles;
