import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Vehicle } from "../utils/_interface";

const vehicle: Vehicle = {};

export const setVehicle = createAction<Vehicle>("setVehicle");

const vehicleReducer = createReducer(vehicle, (builder) =>
  builder.addCase(setVehicle, (state, action: PayloadAction<Vehicle>) => {
    return action.payload;
  }),
);

export const selectVehicle = (state: RootState) => state.vehicle;
export default vehicleReducer;
