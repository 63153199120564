import React, { FC, useEffect, useState } from "react";
import { DrawerTemplate } from "../../Shared/templates/DrawerTemplate";
import { Box, Paper, styled, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import EditIcon from "@mui/icons-material/Edit";
import Button from "../../Shared/atoms/Button";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import { selectCompany } from "../../Company/services/companyReducer";
import { selectVehicle } from "../store/vehicleReducer";
import useVehicleReq from "../hooks/useVehicleReq";
import VehicleForm from "../organisms/VehicleForm";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";

const SpanCustom = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

const VehicleDetails: FC = () => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const company = useSelector(selectCompany);
  const vehicle = useSelector(selectVehicle);
  const { getVehicle } = useVehicleReq();
  const { downSm, downMd } = useBreakpoints();
  const [open, setOpen] = useState(false);
  useRedirectNotCompany();

  useEffect(() => {
    if (vehicleId != null) {
      if (company.id != null) {
        getVehicle(company.id, vehicleId);
      }
    } else {
      navigate("/vehicule", { replace: true });
    }
  }, [open]);

  const content = {
    infoTitle: "Information:",
    vehicleTitle: "Description du véhicule:",
    updateVehicle: "Modifier le véhicule",
    back: "Retour",
  };

  return (
    <>
      <DialogTemplate
        open={open}
        fullScreen={downMd}
        handleDialog={() => setOpen(false)}
        title={content.updateVehicle}
        main={<VehicleForm vehicleId={vehicleId} edit eventDialog={() => setOpen(false)} />}
      />
      <DrawerTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage
              title={`${vehicle?.brand} ${vehicle?.model}`}
              buttonText={content.back}
              buttonClick={() => navigate(-1)}
            />
            <Paper elevation={3} sx={{ padding: "1rem", marginY: "1rem" }}>
              <Box display="flex" flexDirection={downSm ? "column" : "row"}>
                <Typography
                  textAlign={downSm ? "center" : "start"}
                  variant={downSm ? "h5" : "h3"}
                  component="h3"
                  marginY="1rem"
                >
                  {content.vehicleTitle}
                </Typography>
                <Button eventClick={() => setOpen(true)} label={<EditIcon />} variant="text" />
              </Box>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Type : </SpanCustom>
                {`${vehicle?.type ? vehicle?.type : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Plaque d'immatriculation : </SpanCustom>
                {`${vehicle?.licencePlate ? vehicle?.licencePlate : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Licence de taxi affilié : </SpanCustom>
                {`${vehicle?.taxiLicence ? vehicle?.taxiLicence : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Moteur : </SpanCustom>
                {vehicle?.engine ? vehicle.engine : "..."}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Nombre de sièges : </SpanCustom>
                {vehicle?.nbSeat ? vehicle.nbSeat : "..."}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Couleur : </SpanCustom>
                {vehicle?.color ? vehicle.color : "..."}
              </Typography>
            </Paper>
          </Box>
        }
      />
    </>
  );
};

export default VehicleDetails;
