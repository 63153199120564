/** @jsxImportSource @emotion/react */
import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../services/authReducer";
import { useNavigate } from "react-router-dom";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { yupResolver } from "@hookform/resolvers/yup";
import { forgoPasswordSchema, signInSchema, signUpSchema } from "../../Shared/utils/validation";
import { selectIsSignUp } from "../services/signUpReducer";
import { InputField } from "../../Shared/molecules/fields/InputField";
import { Box, Typography } from "@mui/material";
import { fadeIn } from "../../Shared/utils/keyframesStyle";
import { css } from "@emotion/react";
import Button from "../../Shared/atoms/Button";
import { selectProfile } from "../services/profileReducer";
import { ErrorProps } from "../../Shared/utils/_interface";
import Links from "../../Shared/atoms/Links";

interface AuthFormInterface {
  submitHandler: (data: any) => void;
  signIn?: boolean;
  signUp?: boolean;
  forgotPassword?: boolean;
}

const AuthForm: FC<AuthFormInterface> = ({ submitHandler, signIn, signUp, forgotPassword }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email?: string; password?: string; confirmPassword?: string }>({
    resolver: yupResolver(signUp ? signUpSchema : forgotPassword ? forgoPasswordSchema : signInSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  const dispatch = useDispatch();
  const { token } = useSelector(selectAuth);
  const { type } = useSelector(selectError);
  const { isSignup } = useSelector(selectIsSignUp);
  const { account } = useSelector(selectProfile);
  const navigate = useNavigate();

  useEffect(() => {
    if (token && isSignup) {
      navigate("/inscription/confirmation");
    } else if (token) {
      navigate("/");
    }

    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 100000);

    return () => clearTimeout(timer);
  }, [token, type, isSignup]);

  const content = {
    labelEmail: "Email",
    labelPassword: "Mot de passe",
    labelConfirmPassword: "Confirmation du mot de passe",
    forgotPassword: "mot-de-passe-oublie",
    buttonEnd: "Terminer",
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100%"
      css={css`
        animation: ${fadeIn} 1s ease-in-out;
      `}
    >
      <Box display="flex" flexDirection="column" alignItems="center" justifyItems="center" width="100%">
        <InputField
          name="email"
          type="email"
          error={errors}
          control={control}
          margin="1rem 0"
          placeholder={content.labelEmail}
          defaultValue={account?.email ? account.email : ""}
        />
        {!forgotPassword && (
          <InputField
            isPassword
            name="password"
            error={errors}
            control={control}
            margin="1rem 0"
            placeholder={content.labelPassword}
          />
        )}
        {signUp && (
          <InputField
            isPassword
            name="confirmPassword"
            error={errors}
            control={control}
            margin="1rem 0"
            placeholder={content.labelConfirmPassword}
          />
        )}
        {signIn && (
          <Typography width="95%" fontSize="12px" textAlign="right" marginTop="-1rem" marginBottom="1rem">
            <Links title={content.forgotPassword} to="/mot-de-passe-oublie" textTransform="none" fontSize="12px" />
          </Typography>
        )}
        <Button
          label={signUp ? "S'inscrire" : forgotPassword ? "Envoyer" : "Se connecter"}
          eventClick={handleSubmit(submitHandler)}
        />
      </Box>
    </Box>
  );
};

export default AuthForm;
