import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Profile } from "../../AuthProfile/utils/_interface";

const customers: Profile[] = [];

export const setCustomers = createAction<Profile[]>("setCustomers");

const customersReducer = createReducer(customers, (builder) =>
  builder.addCase(setCustomers, (state, action: PayloadAction<Profile[]>) => {
    return action.payload;
  }),
);

export const selectCustomers = (state: RootState) => state.customers;
export default customersReducer;
