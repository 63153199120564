import React, { FC, useEffect } from "react";
import { AccordionDetails, Box, Link, Paper, styled, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeadInfoPage from "../../Shared/molecules/HeadInfoPage";
import { saveRace, selectRace } from "../services/raceReducer";
import useRaceReq from "../services/useRaceReq";
import { selectCompany } from "../../Company/services/companyReducer";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import useRedirectNotCompany from "../../Shared/services/useRedirectNotCompany";
import { getColorStatus, getLabelStatus, getLabelTypeRace, getStatusMessage } from "../utils/handleRace";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { ErrorProps } from "../../Shared/utils/_interface";
import { Race } from "../utils/_interface";
import { DrawerDriverTemplate } from "../../Shared/templates/DrawerDriverTemplate";
import { colors } from "../../Shared/utils/constants";
import { ManagementDriverStatusRace } from "../organisms/ManagementDriverStatusRace";

const SpanCustom = styled("span")(() => ({
  fontWeight: "bold",
  fontSize: "1rem",
}));

const RaceDriverDetails: FC = () => {
  const navigate = useNavigate();
  const { raceId } = useParams();
  const dispatch = useDispatch();
  const race = useSelector(selectRace);
  const { type } = useSelector(selectError);
  const { downSm } = useBreakpoints();
  const { getRace } = useRaceReq();
  const company = useSelector(selectCompany);
  useRedirectNotCompany();

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    return () => clearTimeout(timer);
  }, [type]);

  useEffect(() => {
    if (raceId != null) {
      getRace(raceId);
    } else {
      navigate("/course/a-venir", { replace: true });
    }
  }, [company.drivers]);

  const handleBack = () => {
    navigate(-1);
    const timer = setTimeout(() => {
      dispatch(saveRace({} as Race));
    }, 500);
    return () => clearTimeout(timer);
  };

  const content = {
    infoTitle: "Information: ",
    dataTimeRace: "Date et heure de la course: ",
    updateRace: "Modifier la course",
    startingAddress: "Adresse de départ: ",
    arrivalAddress: "Adresse d'arrivée: ",
    customer: "Client: ",
    customerPhone: "Tel° : ",
    validate: "Valider",
    back: "Retour",
    status: "Status actuel: ",
    statutes: "Les statuts",
    editStatus: "Choisir un statut",
  };

  return (
    <>
      <DrawerDriverTemplate
        main={
          <Box marginX="1rem">
            <HeadInfoPage title={`course numero: # ${raceId}`} buttonText={content.back} buttonClick={handleBack} />
            {/* INFORMATION CHAUFFEUR */}
            <Paper
              elevation={3}
              sx={{ ...(downSm ? { padding: ".5rem" } : { padding: "1rem" }), marginBottom: "1rem" }}
            >
              <Box display="flex" justifyContent="space-between" flexDirection={downSm ? "column" : "row"}>
                <Typography
                  textAlign={downSm ? "center" : "start"}
                  variant={downSm ? "h5" : "h3"}
                  component="h3"
                  marginY="1rem"
                >
                  {content.infoTitle}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  bgcolor={getColorStatus(race.lastStatus)}
                  component="p"
                  borderRadius="5px"
                  padding="5px"
                  marginY="auto"
                >
                  <SpanCustom>{content.status}</SpanCustom>
                  {getLabelStatus(race.lastStatus)}
                </Typography>
              </Box>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.dataTimeRace}</SpanCustom>
                {`${new Date(race.dateTimeRace!).toLocaleString()}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.startingAddress}</SpanCustom>
                {race.startingAddress}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.arrivalAddress}</SpanCustom>
                {race.arrivalAddress}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>{content.customer}</SpanCustom>
                {race.customer?.firstName} {race.customer?.lastName}
              </Typography>
              <Link href={`tel:+${race.customer?.phone}`} sx={{ color: colors.black, textDecoration: "none" }}>
                <Typography variant="body2" component="p" marginY=".5rem">
                  <SpanCustom>{`Tel: ${race.customer?.phone}`}</SpanCustom>
                </Typography>
              </Link>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>type : </SpanCustom>
                {`${race.type ? getLabelTypeRace(race.type) : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                <SpanCustom>Commentaire : </SpanCustom>
                {`${race.note ? race.note : "..."}`}
              </Typography>
              <Typography variant="body2" component="p" marginY=".5rem">
                {race.driver ? (
                  <>
                    <SpanCustom>Course attribuée" à : </SpanCustom>
                    {`${race.driver?.firstName} ${race.driver?.lastName}`}
                  </>
                ) : (
                  <SpanCustom>Course non attribuée. </SpanCustom>
                )}
              </Typography>
              {/* RACESTATUS */}
              <Paper elevation={4} sx={{ ...(downSm ? { padding: 0 } : { padding: "1rem" }) }}>
                <Box display="flex" flexDirection={downSm ? "column" : "row"} alignItems="center" paddingY="10px">
                  <Typography fontWeight="bold" variant={downSm ? "body1" : "h5"} flexGrow={1}>
                    {content.statutes}
                  </Typography>
                  <ManagementDriverStatusRace raceId={raceId!} currentStatus={race.lastStatus} />
                </Box>
                {Array.isArray(race.raceStatus) && race.raceStatus?.length ? (
                  race.raceStatus.map((statut, index) => {
                    const fullName = `${statut.firstName} ${statut.lastName}`;
                    const assigned = `${race.driver?.firstName} ${race.driver?.lastName}`;
                    return (
                      <AccordionDetails key={index} sx={{ bgcolor: getColorStatus(statut.type) }}>
                        <Typography variant="body2" component="p">
                          <SpanCustom>Description : </SpanCustom>
                          {getStatusMessage(statut.type, fullName, assigned, statut.accountTypeId)}
                        </Typography>
                        <Typography variant="body2" component="p">
                          <SpanCustom>Établis-le : </SpanCustom>
                          {`${statut.createdAt ? new Date(statut.createdAt!).toLocaleString() : "..."}`}
                        </Typography>
                      </AccordionDetails>
                    );
                  })
                ) : (
                  <></>
                )}
                {race.cancellation !== null && (
                  <Box display="flex" flexDirection={downSm ? "column" : "row"} alignItems="center" paddingY="10px">
                    <Typography fontWeight="bold" variant={downSm ? "body1" : "h5"}>
                      Motif de l'annulation :
                    </Typography>
                    <Typography variant="body1" component="p" margin="4px 0 0 4px">
                      {race.cancellation}
                    </Typography>
                  </Box>
                )}
              </Paper>
            </Paper>
          </Box>
        }
      />
    </>
  );
};

export default RaceDriverDetails;
