import React, { FC, useEffect, useMemo, useState } from "react";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { colors } from "../../utils/constants";
import * as hookForm from "react-hook-form";
import { FieldErrors } from "react-hook-form";

const textFieldCustom = {
  "& .MuiOutlinedInput-root": {
    color: colors.black,
    "&:hover fieldset": {
      borderColor: colors.blue,
    },
    "&.Mui-focused fieldset": {
      borderColor: colors.blue,
    },
  },
  label: {
    color: colors.black,
  },
};

const autocompleteService = { current: null };

interface AutocompleteFieldProps {
  setStarting?: (position: google.maps.LatLngLiteral) => void;
  control: hookForm.Control<any> | undefined;
  error: FieldErrors;
  name: string;
  label?: string;
  defaultValue?: any;
  disabled?: boolean;
  helperText?: string;
  margin?: string;
}

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}

interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export const AutocompleteField: FC<AutocompleteFieldProps> = ({
  setStarting,
  control,
  error,
  name,
  label,
  defaultValue,
  helperText,
  margin,
}) => {
  const [fieldValue, setFieldValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<readonly PlaceType[]>([]);

  const fetch = useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }
    if (inputValue === "") {
      setOptions(fieldValue ? [fieldValue] : []);
      return undefined;
    }
    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];
        if (fieldValue) {
          newOptions = [fieldValue];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [fieldValue, inputValue, fetch]);

  const handleLatLng = async (description: string | undefined) => {
    const results = await getGeocode({ address: description });
    const { lat, lng } = await getLatLng(results[0]);
    if (setStarting) {
      setStarting({ lat, lng });
    }
  };

  return (
    <hookForm.Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <Autocomplete
          ref={ref}
          getOptionLabel={(option) => (typeof option === "string" ? option : option.description)}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={fieldValue}
          onChange={(event: any, newValue: PlaceType | null) => {
            setOptions(newValue ? [newValue, ...options] : [defaultValue, ...options]);
            setFieldValue(newValue);
            handleLatLng(newValue?.description).then();
            onChange(newValue?.description);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              value={value}
              onBlur={onBlur}
              helperText={(error[name] && error[name]?.message) || helperText}
              sx={{ ...textFieldCustom, margin: margin, width: "100%" }}
            />
          )}
          renderOption={(props, option) => {
            const matches = option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match: any) => [match.offset, match.offset + match.length]),
            );
            return (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box component={LocationOnIcon} sx={{ color: "text.secondary", mr: 2 }} />
                  </Grid>
                  <Grid item xs>
                    {parts.map((part, index) => {
                      return (
                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                          {part.text}
                        </span>
                      );
                    })}
                    <Typography variant="body2" color="text.secondary">
                      {option.structured_formatting.secondary_text}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    />
  );
};

AutocompleteField.defaultProps = {
  margin: ".5rem 0",
  label: "",
};
