import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";

const signUp: { isSignup: boolean } = { isSignup: false };

export const setIsSignUp = createAction<{ isSignup: boolean }>("setIsSignUp");

const signUpReducer = createReducer(signUp, (builder) =>
  builder.addCase(setIsSignUp, (state, action: PayloadAction<{ isSignup: boolean }>) => {
    return action.payload;
  }),
);

export const selectIsSignUp = (state: RootState) => state.signUp;
export default signUpReducer;
