import React, { FC } from "react";
import { Box } from "@mui/material";
import Title from "../atoms/Title";
import Button from "../atoms/Button";
import useBreakpoints from "../services/useBreakpoints";

interface HeadCustomerInfoProps {
  title: string;
  buttonText: string;
  buttonClick?: () => void;
}

const HeadInfoPage: FC<HeadCustomerInfoProps> = ({ title, buttonText, buttonClick }) => {
  const { downSm } = useBreakpoints();
  return (
    <Box
      display="flex"
      flexDirection={downSm ? "column" : "row"}
      alignItems={downSm ? "center" : "start"}
      justifyContent="space-between"
    >
      <Title
        title={title}
        component="h1"
        variant="h4"
        textAlign="left"
        fontWeight="bold"
        sxTitle={{ marginBottom: "2rem" }}
      />
      <Button label={buttonText} eventClick={buttonClick} />
    </Box>
  );
};

export default HeadInfoPage;
