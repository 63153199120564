import React, { FC, ReactNode } from "react";
import { Accordion as AccordionMUI, AccordionDetails, AccordionSummary, styled, SxProps } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Title from "../../Shared/atoms/Title";
import { colors } from "../../Shared/utils/constants";

interface AccordionProps {
  children: ReactNode;
  title: string;
  sx?: SxProps;
  eventClick?: () => void;
}

const MuiAccordion = styled(AccordionMUI)({
  "&::before": {
    opacity: 0,
  },
});

const AccordionTemplate: FC<AccordionProps> = ({ children, title, eventClick, sx }) => {
  return (
    <MuiAccordion elevation={0} disableGutters={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ padding: "0", borderBottom: "solid .5px", borderColor: colors.grayBorder }}
        onClick={eventClick}
      >
        <Title component="h3" fontSize="20px" fontWeight={500} title={title} />
      </AccordionSummary>
      <AccordionDetails sx={{ ...sx, padding: 0, marginTop: "20px" }}>{children}</AccordionDetails>
    </MuiAccordion>
  );
};

export default AccordionTemplate;
