import { colors } from "../../Shared/utils/constants";
import { RaceType, StatusType } from "../../Shared/utils/types";

export const getLabelTypeRace = (type: RaceType) => {
  switch (type) {
    case "paying":
      return "Payante";
    case "contract":
      return "Contrat";
    case "medical":
      return "Médical";
    case "school":
      return "École";
  }
};

export const getLabelStatus = (statusRace?: StatusType) => {
  switch (statusRace) {
    case "unassigned":
      return "Non affectée";
    case "assigned":
      return "Affectée";
    case "accept":
      return "Accepter";
    case "denied":
      return "Refusée";
    case "approaching":
      return "En approche";
    case "waiting":
      return "En attente";
    case "started":
      return "Commencée";
    case "completed":
      return "Terminer";
    case "cancelled":
      return "Annuler";
  }
};

export const getColorStatus = (statusRace?: StatusType) => {
  switch (statusRace) {
    case "unassigned":
      return colors.orange;
    case "assigned":
      return colors.blueDacnis;
    case "accept":
      return colors.success;
    case "denied":
      return colors.error;
    case "approaching":
      return colors.warning;
    case "waiting":
      return colors.pink;
    case "started":
      return colors.blue;
    case "completed":
      return colors.gray;
    case "cancelled":
      return colors.purple;
  }
};

export const handleChangeStatusMessage = (status: StatusType | undefined) => {
  switch (status) {
    case "unassigned":
      return "Aucun chauffeur défini pour cette course!";
    case "assigned":
      return "Changer de statut:";
    case "accept":
      return "Changer de statut:";
    case "denied":
      return "En attente d'une nouvelle instruction";
    case "approaching":
      return "Changer de statut:";
    case "waiting":
      return "Changer de statut:";
    case "started":
      return "Changer de statut:";
    case "completed":
      return "Course terminée.";
    case "cancelled":
      return "Course annulée.";
  }
};

export const getStatusMessage = (statusCourse?: StatusType, profile?: string, assigned?: string, isAdmin?: number) => {
  const accountType = isAdmin === 1 ? "administrateur " : "chauffeur";
  switch (statusCourse) {
    case "unassigned":
      return `Création de la course sans affectation par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}.`;
    case "assigned":
      return `Affectation de la course au chauffeur ${assigned}.`;
    case "accept":
      return `Course acceptée par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}.`;
    case "denied":
      return `Course refusée par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}.`;
    case "approaching":
      return `En approche de la course par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}.`;
    case "waiting":
      return `
       ${isAdmin === 1 ? "Administrateur " : "Chauffeur"}
       ${profile} en attente au point de rendez-vous de la course.`;
    case "started":
      return `Course commencée par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}.`;
    case "completed":
      return `Course terminée par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}.`;
    case "cancelled":
      return `Course annuler par ${isAdmin === 1 ? "l'" : "le"} ${accountType} ${profile}`;
  }
};
