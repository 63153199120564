import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import "@fontsource/roboto/700.css";
import Box from "@mui/material/Box";
import ILLUSTRATION_404 from "../../assets/illustrations/404.svg";
import Container from "@mui/material/Container";
import Button from "../atoms/Button";

const content = {
  button: "retour à l'accueil",
  title: "Désolé, page non trouvée !",
  subject:
    "Nous n'avons pas pu trouver la page que vous recherchez. Peut-être avez-vous mal saisi l'URL? Vérifiez votre orthographe.",
};

const NotFound: FC = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg">
      <Box
        display="flex"
        minHeight="100vh"
        margin="auto"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography fontWeight="bold" fontSize={{ xs: 30, sm: 40 }} variant="body1" align="center">
          {content.title}
        </Typography>
        <Typography variant="subtitle1" align="center" margin="2rem 1rem">
          {content.subject}
        </Typography>
        <Box component="img" width={{ xs: 300, sm: 400, md: 500 }} src={ILLUSTRATION_404} alt="illustration page 404" />
        <Button label={content.button} eventClick={() => navigate("/")} />
      </Box>
    </Container>
  );
};

export default NotFound;
