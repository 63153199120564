import React, { FC } from "react";
import * as hookForm from "react-hook-form";
import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

interface RadioButtonGroupProps {
  control: hookForm.Control<any> | undefined;
  name: string;
  choices: { key: string; label: string }[];
  label?: string;
  defaultValue?: string;
}

export const RadioButtonGroup: FC<RadioButtonGroupProps> = ({
  control,
  choices,
  defaultValue,
  name,
  label,
}: RadioButtonGroupProps) => {
  return (
    <Box>
      <FormLabel>{label}</FormLabel>
      <hookForm.Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <RadioGroup value={value || ""} onChange={(newValue) => onChange(newValue)}>
            {choices.map((choice) => (
              <FormControlLabel key={choice.key} value={choice.key} control={<Radio />} label={choice.label} />
            ))}
          </RadioGroup>
        )}
      />
    </Box>
  );
};
