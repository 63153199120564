import React, { FC } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import NotFound from "../Shared/pages/NotFound";
import SignIn from "../AuthProfile/pages/SignIn";
import Dashboard from "../Shared/pages/Dashboard";
import SignUp from "../AuthProfile/pages/SignUp";
import Profile from "../AuthProfile/pages/Profile";
import SignUpConfirm from "../AuthProfile/pages/SuccessSignUp";
import RacesWrapper from "../Race/pages/RacesWrapper";
import TableRacesUpcoming from "../Race/pages/TableRacesUpcoming";
import Customers from "../Customer/pages/Customers";
import PrivateRoute from "./PrivateRoute";
import CustomerDetails from "../Customer/pages/CustomerDetails";
import Drivers from "../Driver/pages/Drivers";
import DriverDetails from "../Driver/pages/DriverDetails";
import Vehicles from "../Vehicle/pages/Vehicles";
import VehicleDetails from "../Vehicle/pages/VehicleDetails";
import RaceForm from "../Race/organisms/RaceForm";
import { Maps } from "../Shared/pages/Maps";
import RaceDetails from "../Race/pages/RaceDetails";
import TableRacesArchived from "../Race/pages/TableRacesArchived";
import TableRacesFinish from "../Race/pages/TableRacesFinish";
import { useSelector } from "react-redux";
import { selectProfile } from "../AuthProfile/services/profileReducer";
import DashboardDriver from "../Shared/pages/DashboardDriver";
import RacesDriverWrapper from "../Race/pages/RacesDriverWrapper";
import RaceDriverDetails from "../Race/pages/RaceDriverDetails";
import ForgotPassword from "../AuthProfile/pages/ForgotPassword";
import ResetPassword from "../AuthProfile/pages/ResetPassword";

const Router: FC = () => {
  const location = useLocation();
  const profile = useSelector(selectProfile);

  return (
    <Routes key={location.pathname} location={location}>
      <Route path="/connexion" element={<SignIn />} />
      <Route path="/inscription" element={<SignUp />} />
      <Route path="/mot-de-passe-oublie" element={<ForgotPassword />} />
      <Route path="/reinitialiser-mot-de-passe/:email/:token" element={<ResetPassword />} />
      <Route element={<PrivateRoute />}>
        <Route path="/" element={profile.account?.accountTypeId === 1 ? <Dashboard /> : <DashboardDriver />} />
        <Route path="/inscription/confirmation" element={<SignUpConfirm />} />
        <Route path="/profil" element={<Profile />} />
        <Route element={profile.account?.accountTypeId === 1 ? <RacesWrapper /> : <RacesDriverWrapper />}>
          <Route path="/course/nouvelle" element={<RaceForm />} />
          <Route path="/course/a-venir" element={<TableRacesUpcoming />} />
          <Route path="/course/terminer" element={<TableRacesFinish />} />
          <Route path="/course/archive" element={<TableRacesArchived />} />
        </Route>
        <Route
          path="/course/detail/:raceId"
          element={profile.account?.accountTypeId === 1 ? <RaceDetails /> : <RaceDriverDetails />}
        />
        <Route path="/client" element={<Customers />} />
        <Route path="/client/detail/:customerId" element={<CustomerDetails />} />
        <Route path="/chauffeur" element={<Drivers />} />
        <Route path="/chauffeur/detail/:driverId" element={<DriverDetails />} />
        <Route path="/vehicule" element={<Vehicles />} />
        <Route path="/vehicule/detail/:vehicleId" element={<VehicleDetails />} />
        <Route path="/carte" element={<Maps />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
