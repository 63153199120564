import React, { FC, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material";
import { Order } from "../../Shared/utils/types";
import HeadRaceTable from "../organisms/HeadRaceTable";
import { useSelector } from "react-redux";
import { frFR } from "@mui/material/locale";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import useFilterRace from "../services/useFilterRace";
import { selectCompany } from "../../Company/services/companyReducer";
import { getColorStatus, getLabelStatus } from "../utils/handleRace";
import ToolbarRaceTable from "../organisms/ToolbarRaceTable";
import { headCellRace } from "../utils/headCellRace";
import { DataRace, Race } from "../utils/_interface";
import { useNavigate } from "react-router-dom";
import useRaceReq from "../services/useRaceReq";
import { selectProfile } from "../../AuthProfile/services/profileReducer";

interface TableRacesTemplateProps {
  racesRows: Race[];
}

const TableRacesTemplate: FC<TableRacesTemplateProps> = ({ racesRows }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { getRaces, getDriverRaces } = useRaceReq();
  const company = useSelector(selectCompany);
  const profile = useSelector(selectProfile);
  const { applySortFilter, getComparator } = useFilterRace();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof DataRace>("dateTimeRace");
  const [filterDate, setFilterDate] = useState("");
  const [filterDriver, setFilterDriver] = useState("");
  const [filterCustomer, setFilterCustomer] = useState("");
  const [filterStreet, setFilterStreet] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - racesRows.length) : 0;

  useEffect(() => {
    if (profile.account?.accountTypeId === 1) {
      if (company.id != null) {
        getRaces(company.id);
      }
    } else {
      getDriverRaces();
    }
  }, []);

  const filteredDateRaces = applySortFilter(
    racesRows,
    getComparator(order, orderBy),
    filterDate,
    filterDriver,
    filterCustomer,
    filterStreet,
  );

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof DataRace) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const content = {
    titleMessage: "Non trouvée!!",
    textMessage: "Aucun résultat trouvé pour cette recherche. Essayez  une autre saisie.",
  };

  return (
    <ThemeProvider theme={createTheme(theme, frFR)}>
      <ToolbarRaceTable
        filterDate={filterDate}
        filterDriver={filterDriver}
        filterCustomer={filterCustomer}
        filterStreet={filterStreet}
        onFilterDate={(event) => setFilterDate(event.target.value)}
        onFilterDriver={(event) => setFilterDriver(event.target.value)}
        onFilterCustomer={(event) => setFilterCustomer(event.target.value)}
        onFilterStreet={(event) => setFilterStreet(event.target.value)}
      />
      <TableContainer>
        <Table size="medium">
          <HeadRaceTable
            headCells={headCellRace}
            order={order}
            orderBy={orderBy}
            sortingOnRequest={handleRequestSort}
            accountType={profile.account?.accountTypeId}
          />
          <TableBody>
            {filteredDateRaces
              .slice()
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={row.raceId}
                    sx={{ backgroundColor: getColorStatus(row.statusRace), cursor: "pointer" }}
                    onClick={() => navigate(`/course/detail/${row.raceId}`, { state: { raceId: row.raceId } })}
                  >
                    <TableCell component="th" scope="row" padding="none" align="center">
                      {getLabelStatus(row.statusRace)}
                    </TableCell>
                    <TableCell align="center">{row.dateTimeRace}</TableCell>
                    <TableCell align="center">{row.customer}</TableCell>
                    <TableCell align="center">{row.startingAddress}</TableCell>
                    <TableCell align="center">{row.arrivalAddress}</TableCell>
                    {profile.account?.accountTypeId === 1 && <TableCell align="center">{row.driver}</TableCell>}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {filteredDateRaces.length === 0 && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ paddingY: 3 }}>
                  <Typography gutterBottom align="center" variant="subtitle1">
                    {content.titleMessage}
                  </Typography>
                  <Typography variant="body2" align="center">
                    {content.textMessage}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[15, 30, 45]}
        component="div"
        count={racesRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event: unknown, newPage: number) => setPage(newPage)}
        onRowsPerPageChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </ThemeProvider>
  );
};

export default TableRacesTemplate;
