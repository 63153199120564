import React, { FC, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, lighten, Link, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { selectCustomers } from "../services/customersReducer";
import { colors } from "../../Shared/utils/constants";
import Button from "../../Shared/atoms/Button";
import PageviewIcon from "@mui/icons-material/Pageview";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import { useNavigate } from "react-router-dom";

const SpanCustom = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const CustomersAccordionInfo: FC = () => {
  const navigate = useNavigate();
  const { downSm } = useBreakpoints();
  const customers = useSelector(selectCustomers);
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box marginY="1rem">
      {Array.isArray(customers) && customers.length ? (
        customers.map((customer, index) => {
          return (
            <Accordion key={index} expanded={expanded === customer.id} onChange={handleChange(customer.id!)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={customer.id?.toString()}>
                <Typography fontWeight="bold" width="33%" variant={downSm ? "body1" : "h5"} sx={{ flexShrink: 0 }}>
                  {`${customer.firstName} ${customer.lastName}`}
                </Typography>
                <Link href={`tel:+${customer.phone}`} sx={{ flexGrow: 1, textDecoration: "none" }}>
                  <Typography variant={downSm ? "body2" : "h6"} flexGrow={1} color={lighten(colors.black, 0.5)}>
                    {customer.phone ? customer.phone : "..."}
                  </Typography>
                </Link>
                <Button
                  eventClick={() => navigate(`/client/detail/${customer.id}`, { state: { customerId: customer.id } })}
                  label={<PageviewIcon />}
                  variant="text"
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" component="p">
                  <SpanCustom>Adresse : </SpanCustom>
                  {`${customer.address ? customer.address : "..."}`}
                </Typography>
                <Typography variant="body2" component="p">
                  <SpanCustom>Téléphone : </SpanCustom>
                  {customer.phone ? customer.phone : "..."}
                </Typography>
                <Typography variant="body2" component="p">
                  <SpanCustom>Information : </SpanCustom>
                  {`${customer.note ? customer.note : "..."}`}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <></>
      )}
    </Box>
  );
};
