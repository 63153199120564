import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { selectAuth } from "../../AuthProfile/services/authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { setCustomers } from "./customersReducer";
import { Profile } from "../../AuthProfile/utils/_interface";
import { setCustomer } from "./customerReducer";

const useCustomerReq = () => {
  const dispatch = useDispatch();
  const { token, profileId } = useSelector(selectAuth);
  const [sendRequest] = useHttp();

  // Get the list of customers from the database
  const getCustomers = useCallback(() => {
    const config = requestConfigBuilder("GET", `customer/profile=${profileId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { customers } = response;
        dispatch(setCustomers(customers));
      }
    });
  }, []);

  // Get a customer from the database
  const getCustomer = useCallback((customerId: string) => {
    const config = requestConfigBuilder("GET", `customer/profile=${profileId}/customer=${customerId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { customer } = response;
        dispatch(setCustomer(customer));
      }
    });
  }, []);

  // add a customer to the database
  const addCustomer = useCallback((data: Profile) => {
    const config = requestConfigBuilder("POST", `customer/profile=${profileId}/new`, token, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCustomers();
      }
    });
  }, []);

  // edit a customer in the database
  const putCustomer = useCallback((customerId: string, data: Profile) => {
    const config = requestConfigBuilder(
      "PUT",
      `customer/profile=${profileId}/edit/customer=${customerId}`,
      token,
      data,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCustomers();
      }
    });
  }, []);

  // delete a customer in the database
  const deleteCustomer = useCallback((customerId: string) => {
    const config = requestConfigBuilder("DELETE", `customer/profile=${profileId}/delete/customer=${customerId}`, token);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCustomers();
      }
    });
  }, []);

  return { getCustomers, getCustomer, addCustomer, putCustomer, deleteCustomer };
};

export default useCustomerReq;
