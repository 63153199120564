import React, { FC } from "react";
import { Box } from "@mui/material";

interface DistanceProps {
  leg: google.maps.DirectionsLeg;
}

const Distance: FC<DistanceProps> = ({ leg }) => {
  const litesPerKM = 13 / 100;
  const gasLitresCost = 2;
  const litreCostKM = litesPerKM * gasLitresCost;

  if (!leg.distance || !leg.duration) return null;

  const cost = Math.floor((leg.distance.value / 1000) * litreCostKM);

  return (
    <Box>
      <p>
        Ce chauffeur est à <span>{leg.distance?.text}</span> du point de départ, il faudrait{" "}
        <span>{leg.duration?.text}</span> pour arriver
      </p>
      <p>
        Cela représente un coût approximatif de <span>{new Intl.NumberFormat().format(cost)}</span> euros, pour une
        valeur de 2€ le litre de gasoil.
      </p>
    </Box>
  );
};

export default Distance;
