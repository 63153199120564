import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const useBreakpoints = () => {
  const theme = useTheme();

  const upXs = useMediaQuery(theme.breakpoints.up("xs"));

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  const upMd = useMediaQuery(theme.breakpoints.up("md"));

  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const betweenXsSm = useMediaQuery(theme.breakpoints.between("xs", "sm"));

  const betweenSmMd = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const betweenMdLg = useMediaQuery(theme.breakpoints.between("md", "lg"));

  const betweenXsMd = useMediaQuery(theme.breakpoints.between("xs", "md"));

  return { upXs, downSm, downMd, upMd, downLg, betweenXsSm, betweenSmMd, betweenMdLg, betweenXsMd };
};

export default useBreakpoints;

// TEMPORARY
//``xs, extra-petit : 0px``
// ``sm, petit : 600px``
// ``md, moyen : 900px``
// ``lg, grand : 1 200 pixels``
// ``xl, extra-large : 1536px``
