import { keyframes } from "@emotion/react";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const fadeInDown = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -25%, 0);
  }
  100% {
    opacity: 1;
    transform: none
  }
`;

export const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: none
  }
  100% {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }
`;

export const flash = keyframes`
  0%, 50%, 100% {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
`;
