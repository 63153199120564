import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { selectAuth } from "../../AuthProfile/services/authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import useCompanyReq from "../../Company/services/useCompanyReq";
import { setVehicle } from "../store/vehicleReducer";
import { Vehicle } from "../utils/_interface";

const useVehicleReq = () => {
  const dispatch = useDispatch();
  const [sendRequest] = useHttp();
  const { getCompany } = useCompanyReq();
  const { token, profileId } = useSelector(selectAuth);

  // Get a vehicle from the database
  const getVehicle = useCallback((companyId: number, vehicleId: string) => {
    const config = requestConfigBuilder(
      "GET",
      `vehicle/profile=${profileId}/company=${companyId}/vehicle=${vehicleId}`,
      token,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { vehicle } = response;
        dispatch(setVehicle(vehicle));
      }
    });
  }, []);

  // add a vehicle to the database
  const addVehicle = useCallback((companyId: number, data: Vehicle) => {
    const config = requestConfigBuilder("POST", `vehicle/profile=${profileId}/company=${companyId}/new`, token, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCompany(companyId);
      }
    });
  }, []);

  // edit a vehicle in the database
  const putVehicle = useCallback((companyId: number, vehicleId: string, data: Vehicle) => {
    const config = requestConfigBuilder(
      "PUT",
      `vehicle/profile=${profileId}/company=${companyId}/edit/vehicle=${vehicleId}`,
      token,
      data,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCompany(companyId);
      }
    });
  }, []);

  // archive a vehicle in the database
  const archiveVehicle = useCallback((companyId: number, vehicleId: string) => {
    const config = requestConfigBuilder(
      "DELETE",
      `vehicle/profile=${profileId}/company=${companyId}/delete/vehicle=${vehicleId}`,
      token,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCompany(companyId);
      }
    });
  }, []);

  return { getVehicle, addVehicle, putVehicle, archiveVehicle };
};

export default useVehicleReq;
