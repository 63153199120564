import { DataRace } from "./_interface";

export interface HeadCellsProps {
  id: keyof DataRace;
  label: string;
  padding: "none" | "normal" | "checkbox";
  minWidth?: number;
}

export const headCellRace: readonly HeadCellsProps[] = [
  {
    id: "statusRace",
    padding: "checkbox",
    label: "Statut",
    minWidth: 170,
  },
  {
    id: "dateTimeRace",
    padding: "none",
    label: "Date/Heure",
    minWidth: 170,
  },
  {
    id: "customer",
    padding: "none",
    label: "Client",
    minWidth: 170,
  },
  {
    id: "startingAddress",
    padding: "none",
    label: "Départ",
    minWidth: 170,
  },
  {
    id: "arrivalAddress",
    padding: "none",
    label: "Arrivée",
    minWidth: 170,
  },
];
