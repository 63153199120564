import React from "react";
import useAuthReq from "../services/useAuthReq";
import { AuthTemplate } from "../templates/AuthTemplate";
import AuthForm from "../organisms/AuthForm";
import { Typography } from "@mui/material";
import Links from "../../Shared/atoms/Links";

const SignIn = () => {
  const { postAuthRequest } = useAuthReq("auth/login");

  const content = {
    title: "CONNEXION",
    subTitle: "Nous sommes ravis de vous revoir",
    signUpMessage: "Vous n'avez pas encore de compte ? ",
    signUp: "Inscrivez-vous",
  };

  return (
    <AuthTemplate
      title={content.title}
      subTitle={content.subTitle}
      main={<AuthForm signIn submitHandler={postAuthRequest} />}
      footer={
        <Typography fontSize="12px" textAlign="center">
          {content.signUpMessage}
          <Links title={content.signUp} to="/inscription" textTransform="none" fontSize="12px" />
        </Typography>
      }
    />
  );
};

export default SignIn;
