import React, { FC } from "react";
import { Box, Card, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { colors } from "../utils/constants";
import useBreakpoints from "../services/useBreakpoints";

const useStyles = makeStyles({
  span: {
    marginLeft: 10,
  },
});

interface CardContainerProps {
  add?: boolean;
  empty?: boolean;
  race?: boolean;
  large?: boolean;
  title?: string;
  colorsStatus?: string;
  subTitle?: string;
  secondSubtitle?: string;
  text?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
}

export const CardContainer: FC<CardContainerProps> = ({
  secondSubtitle,
  add,
  empty,
  race,
  colorsStatus,
  large,
  icon,
  title,
  subTitle,
  text,
  onClick,
}) => {
  const classes = useStyles();
  const { downMd } = useBreakpoints();

  return (
    <Card
      elevation={3}
      onClick={onClick}
      sx={{
        backgroundColor: `${add ? colors.skyBlue : empty ? colors.grayBorder : colors.white}`,
        color: `${empty ? colors.white : colors.black}`,
        display: "flex",
        position: "relative",
        flexDirection: `${large ? "row" : "column"}`,
        justifyContent: "center",
        alignItems: "center",
        height: "145px",
        maxWidth: "380px",
        width: "264px",
        cursor: "pointer",
        textAlign: "center",
        paddingX: "5px",
        margin: ".5rem",
      }}
    >
      {add ? icon : null}
      <Typography fontWeight="500" variant={downMd ? "body2" : "h6"} component="h5">
        <span className={classes.span}>{title}</span>
      </Typography>
      <Typography variant="caption" sx={{ marginBottom: ".5rem", color: colors.navyBlue }}>
        {subTitle}
      </Typography>
      <Typography variant="caption" sx={{ marginBottom: ".5rem", color: colors.navyBlue }}>
        {secondSubtitle}
      </Typography>
      <Typography variant="body2">{text}</Typography>
      {!add && !empty && race && (
        <Box position="absolute" bottom={0} sx={{ bgcolor: colorsStatus, height: "10%", width: "100%" }} />
      )}
    </Card>
  );
};
