export const colors = {
  navyBlue: "#081A3F",
  blue: "#2595e5",
  skyBlue: "#9dd4fa",
  grayBody: "#fafafa",
  grayBorder: "#c4c4c4",
  gray: "#979797",
  white: "#fff",
  black: "#0a0a0a",
  error: "#d32f2f",
  success: "#01b74b",
  warning: "#fada06",
  pink: "#fd5fb9",
  purple: "#a308e7",
  orange: "#ff6600",
  blueDacnis: "#39f3e9",
};
