import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Profile } from "../../AuthProfile/utils/_interface";

const driver: Profile = {} as Profile;

export const setDriver = createAction<Profile>("setDriver");

const driverReducer = createReducer(driver, (builder) =>
  builder.addCase(setDriver, (state, action: PayloadAction<Profile>) => {
    return action.payload;
  }),
);

export const selectDriver = (state: RootState) => state.driver;
export default driverReducer;
