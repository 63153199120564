/** @jsxImportSource @emotion/react */
import React, { FC, Fragment, useRef, useState } from "react";
import { Button, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { StatusType } from "../../Shared/utils/types";
import { fadeIn } from "../../Shared/utils/keyframesStyle";
import { getColorStatus } from "../utils/handleRace";
import EditIcon from "@mui/icons-material/Edit";
import useRaceStatusReq from "../services/useStatusReq";
import { RaceStatus } from "../utils/_interface";
import { useSelector } from "react-redux";
import { selectProfile } from "../../AuthProfile/services/profileReducer";
import { selectRace } from "../services/raceReducer";
import DialogTemplate from "../../Shared/templates/DialogTemplate";
import { CauseCancellationForm } from "./CauseCancellationForm";
import useBreakpoints from "../../Shared/services/useBreakpoints";

export interface RaceMoreStatusProps {
  raceId: string;
}

export const ManagementStatusRace: FC<RaceMoreStatusProps> = ({ raceId }) => {
  const ref = useRef(null);
  const { downSm } = useBreakpoints();
  const { editStatusRace } = useRaceStatusReq();
  const race = useSelector(selectRace);
  const profile = useSelector(selectProfile);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleStatus = async (status: StatusType) => {
    const data: RaceStatus = {
      type: status,
      firstName: profile.firstName,
      lastName: profile.lastName,
    };
    editStatusRace(raceId, data);
    setIsOpen(false);
  };

  const content = {
    editStatus: "Changer de statut",
    causeCancel: "Cause de l'annulation",
  };

  return (
    <Fragment>
      <DialogTemplate
        open={open}
        fullScreen={downSm}
        handleDialog={() => setOpen(false)}
        title={content.causeCancel}
        main={<CauseCancellationForm raceId={raceId} eventDialog={() => setOpen(false)} />}
      />
      <Typography
        variant="body2"
        paddingX="0.4rem"
        margin="0.3rem"
        border={1}
        borderRadius="5px"
        ref={ref}
        onClick={() => setIsOpen(true)}
        css={css`
          animation: ${fadeIn} 0.3s ease-out;
        `}
      >
        {content.editStatus}
        <EditIcon fontSize="small" sx={{ verticalAlign: "middle", marginLeft: "3px" }} />
      </Typography>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        transformOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <MenuItem component={Button} sx={{ backgroundColor: getColorStatus("denied"), width: "90%", marginX: "7px" }}>
          <ListItemText
            onClick={() => handleStatus("denied")}
            primary="Refusée"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem component={Button} sx={{ backgroundColor: getColorStatus("accept"), width: "90%", marginX: "7px" }}>
          <ListItemText
            onClick={() => handleStatus("accept")}
            primary="Accepter"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem
          component={Button}
          sx={{ backgroundColor: getColorStatus("approaching"), width: "90%", marginX: "7px" }}
        >
          <ListItemText
            onClick={() => handleStatus("approaching")}
            primary="En approche"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem component={Button} sx={{ backgroundColor: getColorStatus("waiting"), width: "90%", marginX: "7px" }}>
          <ListItemText
            onClick={() => handleStatus("waiting")}
            primary="En attente"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem component={Button} sx={{ backgroundColor: getColorStatus("started"), width: "90%", marginX: "7px" }}>
          <ListItemText
            onClick={() => handleStatus("started")}
            primary="Commencée"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem
          component={Button}
          sx={{ backgroundColor: getColorStatus("completed"), width: "90%", marginX: "7px" }}
        >
          <ListItemText
            onClick={() => handleStatus("completed")}
            primary="Terminer"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        {race.lastStatus !== "started" && race.lastStatus !== "completed" && (
          <MenuItem
            component={Button}
            sx={{ backgroundColor: getColorStatus("cancelled"), width: "90%", marginX: "7px" }}
          >
            <ListItemText
              onClick={() => setOpen(true)}
              primary="Annuler"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </Fragment>
  );
};
