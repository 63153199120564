import { combineReducers } from "redux";
import auth from "../../AuthProfile/services/authReducer";
import account from "../../AuthProfile/services/accountReducer";
import error from "./errorReducer";
import signUp from "../../AuthProfile/services/signUpReducer";
import profile from "../../AuthProfile/services/profileReducer";
import company from "../../Company/services/companyReducer";
import customers from "../../Customer/services/customersReducer";
import customer from "../../Customer/services/customerReducer";
import races from "../../Race/services/racesReducer";
import race from "../../Race/services/raceReducer";
import driver from "../../Driver/services/driverReducer";
import vehicle from "../../Vehicle/store/vehicleReducer";

export const rootReducer = combineReducers({
  auth,
  account,
  error,
  signUp,
  profile,
  customers,
  customer,
  company,
  races,
  race,
  driver,
  vehicle,
});
