import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Race } from "../utils/_interface";

const races: Race[] = [];

export const saveRaces = createAction<Race[]>("saveRaces");
export const updateRaces = createAction<Race[]>("updateRaces");

const racesReducer = createReducer(races, (builder) =>
  builder
    .addCase(saveRaces, (state, action: PayloadAction<Race[]>) => {
      return action.payload;
    })
    .addCase(updateRaces, (state, action: PayloadAction<Race[]>) => {
      state.push(...action.payload);
    }),
);
export const selectRaces = (state: RootState) => state.races;
export default racesReducer;
