import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Race } from "../utils/_interface";

const race: Race = {} as Race;

export const saveRace = createAction<Race>("setRace");

const raceReducer = createReducer(race, (builder) =>
  builder.addCase(saveRace, (state, action: PayloadAction<Race>) => {
    return action.payload;
  }),
);

export const selectRace = (state: RootState) => state.race;
export default raceReducer;
