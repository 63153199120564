import { createAction, createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../Shared/services/storeConfig";
import { Authenticate } from "../utils/_interface";

const auth: Authenticate = {
  token: "",
  profileId: "",
  accountTypeId: "",
};

export const login = createAction<Authenticate>("login");

const authReducer = createReducer(auth, (builder) =>
  builder.addCase(login, (state, action: PayloadAction<Authenticate>) => {
    return action.payload;
  }),
);

export const selectAuth = (state: RootState) => state.auth;
export default authReducer;
