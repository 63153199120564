import React, { FC } from "react";
import Button from "../atoms/Button";
import { Box, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface DialogTemplateProps {
  open: boolean;
  handleDialog: () => void;
  main?: JSX.Element;
  title?: string;
  fullScreen?: boolean;
}

const DialogTemplate: FC<DialogTemplateProps> = ({ open, handleDialog, title, fullScreen, main }) => {
  return (
    <Dialog fullScreen={fullScreen} open={open} onClose={handleDialog}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          {title} <Button variant="text" label={<CloseIcon />} eventClick={handleDialog} />
        </Box>
      </DialogTitle>
      <DialogContent>{main}</DialogContent>
    </Dialog>
  );
};

export default DialogTemplate;
