import React, { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "../../Shared/atoms/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectError, setError } from "../../Shared/services/errorReducer";
import { customerSchema } from "../../Shared/utils/validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import { InputField } from "../../Shared/molecules/fields/InputField";
import useCustomerReq from "../services/useCustomerReq";
import { Profile as ProfileInterface } from "../../AuthProfile/utils/_interface";
import { selectCustomer } from "../services/customerReducer";
import { AutocompleteField } from "../../Shared/molecules/fields/AutocompleteMapsField";
import { ErrorProps } from "../../Shared/utils/_interface";

interface CustomerFormProps {
  eventDialog?: () => void;
  customerId?: string;
  edit?: boolean;
}

const CustomerForm: FC<CustomerFormProps> = ({ eventDialog, customerId, edit }) => {
  const dispatch = useDispatch();
  const { type } = useSelector(selectError);
  const customer = useSelector(selectCustomer);
  const { addCustomer, putCustomer } = useCustomerReq();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ProfileInterface>({
    resolver: yupResolver(customerSchema),
    reValidateMode: "onBlur",
    shouldFocusError: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => dispatch(setError({} as ErrorProps)), 2000);
    return () => clearTimeout(timer);
  }, [type]);

  const handleCustomer = (data) => {
    const body = { ...data };
    if (edit) {
      if (customerId != null) {
        putCustomer(customerId, body);
      }
    } else {
      addCustomer(body);
    }

    reset({
      lastName: "",
      firstName: "",
      phone: "",
      note: "",
      address: "",
      account: { email: "", password: "" },
    });
    if (eventDialog) {
      eventDialog();
    }
  };

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        {/* -- PROFILE CUSTOMER -- */}
        <InputField
          name="lastName"
          error={errors}
          control={control}
          label="Nom"
          defaultValue={edit ? customer.lastName : ""}
        />
        <InputField
          name="firstName"
          error={errors}
          control={control}
          label="Prénom"
          defaultValue={edit ? customer.firstName : ""}
        />
        <InputField
          name="phone"
          type="tel"
          error={errors}
          control={control}
          label="Téléphone"
          defaultValue={edit ? customer.phone : ""}
        />
        <InputField
          name="note"
          type="textarea"
          error={errors}
          control={control}
          label="Remarque"
          defaultValue={edit ? customer.note : ""}
        />
        <Typography variant="h5">Adresse:</Typography>
        <Typography variant="body2">{customer.address}</Typography>
        <AutocompleteField error={errors} control={control} name="address" />
      </Box>
      <Box justifySelf="start">
        <Button label="Sauvegarder" eventClick={handleSubmit(handleCustomer)} margin="1rem 0" />
      </Box>
    </Box>
  );
};

export default CustomerForm;
