import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuDrawer from "./MenuDrawer";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Divider } from "@mui/material";
import { colors } from "../utils/constants";
import Image from "../atoms/Image";
import pictogramme from "../../assets/logos/pictogramme.svg";
import logoBecose from "../../assets/logos/logoBecose.svg";
import useAuthReq from "../../AuthProfile/services/useAuthReq";
import makeStyles from "@mui/styles/makeStyles";
import useBreakpoints from "../services/useBreakpoints";
import { useSelector } from "react-redux";
import { selectProfile } from "../../AuthProfile/services/profileReducer";

const useStyles = makeStyles({
  link: {
    color: colors.white,
    textDecoration: "none",
  },
});

interface HeaderProps {
  drawerWidth?: number;
}

const Header: FC<HeaderProps> = ({ drawerWidth }) => {
  const classes = useStyles();
  const profile = useSelector(selectProfile);
  const { deleteLogoutRequest } = useAuthReq();
  const { downMd } = useBreakpoints();

  const content = {
    dashboard: "Tableau de bord",
    races: "courses",
    customers: "clients",
    drivers: "chauffeurs",
    vehicles: "véhicules",
    profile: "Profil",
    viewProfile: "Voir Mon profil",
    logout: "Déconnexion",
  };

  return (
    <AppBar position="fixed" sx={{ width: { md: `calc(100% - ${drawerWidth}px)` }, ml: { md: `${drawerWidth}px` } }}>
      <Toolbar>
        <Box component="div" display={{ xs: "none", md: "flex" }} justifyContent="center">
          <Image image={logoBecose} />
        </Box>
        <MenuDrawer isAdmin={profile.account?.accountTypeId === 1} />
        <Box component="div" flexGrow={1} display={{ xs: "flex", md: "none" }} justifyContent="center">
          <Image image={pictogramme} />
        </Box>
        <Box component="div" justifyContent="center" flexGrow={1} display={{ xs: "none", md: "flex" }} />
        <NavLink to="/profil" className={classes.link}>
          {downMd ? <AccountCircleIcon /> : profile.lastName ? `${profile.firstName} ${profile.lastName}` : "Profil"}
        </NavLink>
        <Divider orientation="vertical" flexItem sx={{ backgroundColor: colors.white, margin: ".5rem 1rem" }} />
        <NavLink to="/connexion" className={classes.link} onClick={deleteLogoutRequest}>
          {downMd ? <LogoutIcon /> : content.logout}
        </NavLink>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
