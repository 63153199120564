import React, { FC } from "react";
import { Box } from "@mui/material";
import Title from "../atoms/Title";
import DashboardSection from "../organisms/DashboardSection";
import { useSelector } from "react-redux";
import { CardContainer } from "../molecules/CardContainer";
import { useNavigate } from "react-router-dom";
import { selectRaces } from "../../Race/services/racesReducer";
import { DrawerDriverTemplate } from "../templates/DrawerDriverTemplate";
import { getColorStatus } from "../../Race/utils/handleRace";

const DashboardDriver: FC = () => {
  const navigate = useNavigate();
  const races = useSelector(selectRaces);

  const content = {
    title: "Tableau de bord",

    labelRaces: "Les courses",
    emptyRace: "Pas de course",
  };

  return (
    <>
      <DrawerDriverTemplate
        main={
          <Box marginX="1rem">
            <Title
              title={content.title}
              component="h1"
              variant="h4"
              textAlign="left"
              fontWeight="bold"
              sxTitle={{ marginBottom: "2rem" }}
            />
            <Box display="flex" flexDirection="column">
              <DashboardSection
                title={content.labelRaces}
                emptyCardLabel={content.emptyRace}
                sectionInfo={races}
                seeMorRoute="/course/a-venir"
                cardBuilder={races
                  .slice(0, 6)
                  .filter((race) => race.lastStatus !== "completed" && race.lastStatus !== "cancelled")
                  .map((race) => {
                    const startingAddress = `Départ: ${race.startingAddress}`;
                    const arrivalAddress = `Arriver: ${race.arrivalAddress}`;
                    return (
                      <CardContainer
                        key={race.id}
                        race
                        colorsStatus={getColorStatus(race.lastStatus)}
                        title={new Date(race.dateTimeRace!).toLocaleString()}
                        subTitle={startingAddress}
                        secondSubtitle={arrivalAddress}
                        onClick={() => navigate(`/course/detail/${race.id}`, { state: { raceId: race.id } })}
                      />
                    );
                  })}
              />
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default DashboardDriver;
