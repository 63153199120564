import React, { FC, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, lighten, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { colors } from "../../Shared/utils/constants";
import Button from "../../Shared/atoms/Button";
import PageviewIcon from "@mui/icons-material/Pageview";
import useBreakpoints from "../../Shared/services/useBreakpoints";
import { useNavigate } from "react-router-dom";
import { selectCompany } from "../../Company/services/companyReducer";

const SpanCustom = styled("span")(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const VehiclesAccordionInfo: FC = () => {
  const navigate = useNavigate();
  const { downSm } = useBreakpoints();
  const { vehicles } = useSelector(selectCompany);
  const [expanded, setExpanded] = useState<number | false>(false);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box marginY="1rem">
      {Array.isArray(vehicles) && vehicles.length ? (
        vehicles.map((vehicle, index) => {
          return (
            <Accordion key={index} expanded={expanded === vehicle.id} onChange={handleChange(vehicle.id!)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} id={vehicle.id?.toString()}>
                <Typography fontWeight="bold" width="33%" variant={downSm ? "body1" : "h5"} sx={{ flexShrink: 0 }}>
                  {`${vehicle.brand} ${vehicle.model}`}
                </Typography>
                <Typography variant={downSm ? "body2" : "h6"} flexGrow={1} color={lighten(colors.black, 0.5)}>
                  {vehicle.licencePlate ? vehicle.licencePlate : "..."}
                </Typography>
                <Button
                  eventClick={() => navigate(`/vehicule/detail/${vehicle.id}`, { state: { vehicleId: vehicle.id } })}
                  label={<PageviewIcon />}
                  variant="text"
                />
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" component="p" marginY=".5rem">
                  <SpanCustom>Licence de taxi affilié : </SpanCustom>
                  {`${vehicle?.taxiLicence ? vehicle?.taxiLicence : "..."}`}
                </Typography>
                <Typography variant="body2" component="p" marginY=".5rem">
                  <SpanCustom>Moteur : </SpanCustom>
                  {vehicle?.engine ? vehicle.engine : "..."}
                </Typography>
                <Typography variant="body2" component="p" marginY=".5rem">
                  <SpanCustom>Nombre de sièges : </SpanCustom>
                  {vehicle?.nbSeat ? vehicle.nbSeat : "..."}
                </Typography>
                <Typography variant="body2" component="p" marginY=".5rem">
                  <SpanCustom>Couleur : </SpanCustom>
                  {vehicle?.color ? vehicle.color : "..."}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <></>
      )}
    </Box>
  );
};
