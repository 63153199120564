import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../Shared/services/errorReducer";
import { selectAuth } from "../../AuthProfile/services/authReducer";
import { requestConfigBuilder } from "../../Shared/utils/requestConfigBuilder";
import useHttp from "../../Shared/services/useHttp";
import { Profile } from "../../AuthProfile/utils/_interface";
import { setDriver } from "./driverReducer";
import useCompanyReq from "../../Company/services/useCompanyReq";

const useDriverReq = () => {
  const dispatch = useDispatch();
  const [sendRequest] = useHttp();
  const { getCompany } = useCompanyReq();
  const { token, profileId } = useSelector(selectAuth);

  // Get a driver from the database
  const getDriver = useCallback((companyId: number, driverId: string) => {
    const config = requestConfigBuilder(
      "GET",
      `driver/profile=${profileId}/company=${companyId}/driver=${driverId}`,
      token,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        const { driver } = response;
        dispatch(setDriver(driver));
      }
    });
  }, []);

  // add a driver to the database
  const addDriver = useCallback((companyId: number, data: Profile) => {
    const config = requestConfigBuilder("POST", `driver/profile=${profileId}/company=${companyId}/new`, token, data);
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCompany(companyId);
      }
    });
  }, []);

  // edit a driver in the database
  const putDriver = useCallback((companyId: number, driverId: string, data: Profile) => {
    const config = requestConfigBuilder(
      "PUT",
      `driver/profile=${profileId}/company=${companyId}/edit/driver=${driverId}`,
      token,
      data,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCompany(companyId);
      }
    });
  }, []);

  // archive a driver in the database
  const archiveDriver = useCallback((companyId: number, driverId: string) => {
    const config = requestConfigBuilder(
      "DELETE",
      `driver/profile=${profileId}/company=${companyId}/delete/driver=${driverId}/`,
      token,
    );
    sendRequest(config).then((response) => {
      if (response.status === "error") {
        dispatch(setError(response));
      } else if (response.status === "success") {
        getCompany(companyId);
      }
    });
  }, []);

  return { getDriver, addDriver, putDriver, archiveDriver };
};

export default useDriverReq;
